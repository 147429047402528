import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTable } from "react-table";
import TableSpinner from "../../custom/spinner/TableSpinner";
import TableActionItems from "../../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
import Switch from "../../custom/switch/Switch";
import { noteRefs } from "../../../redux/actions";
import useAxios from "../../../axiosinstance";
import Message from "../../custom/toster/Message";
import Select from 'react-select';
import SelectPlatForm from "./SelectPlatForm";


function OrganizationUserHcpTable({ setUpdatedData, updatedData, usersData, hasMore, updatePagination, orgId, platFormData, totalData, orgName, platform_id, featureOptions }) {
  console.log("usersDatausersData", usersData)

  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const loginType = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  console.log("loginType", loginType.ak_id)
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  const [editPlatformList, setEditPlatFormList] = useState([])
  const [options, setOptions] = useState([])
  const [doc_id, setDocId] = useState("")


  const actionData = {
    view_url: "patient-view",
    edit_url: "edit-user-details",
    delete_url: "",
  };
  //   function handleEdit(row) {
  //     console.log(row);
  //   }
  const data = usersData;



  const handleSwitchChange = (status, id, editData) => {
    let s;
    if (status === 1) {
      s = 0
    } else {
      s = 1
    }
    console.log("status, id", status, id)

    axiosInstance.current.put(
      `extorg/users/updatePractitionerStatus/${loginType.ak_id}/${id}?practitioner=${s}&org_id=${orgId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setMessage("Practitioner Updated")
      setMessageType("success");
      setUpdatedData({
        account_status: editData.account_status,
        ak_id: editData.ak_id,
        created_at: editData.created_at,
        deleted_at: editData.deleted_at,
        email: editData.email,
        firstname: editData.firstname,
        insightStatus: editData.insightStatus,
        interOrgStatus: editData.interOrgStatus,
        lastname: editData.lastname,
        platform: editData.platform,
        practitioner: status === 1 ? 0 :1,
        quick_code: editData.quick_code,
        reportStatus: editData.reportStatus,
        updated_at: editData.updated_at
      })
      setTimeout(() => {
        setMessage(false)
      }, 2000);
    }).catch((err) => {
      setMessage("Practitioner Not Updated")
      setMessageType("error");
    });

  };
  const handleSwitchInsigth = (status, id,editData) => {
    let s;
    if (status) {
      s = false
    } else {
      s = true
    }
    let json = JSON.stringify({
      insightStatus: s,
    })
    axiosInstance.current.put(
      `extorg/org/updateOrgHcp?doc_id=${id}&akou_id=${orgId}`, json,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setMessage("Insight Updated")
      setMessageType("success");
      setUpdatedData({
        account_status: editData.account_status,
        ak_id: editData.ak_id,
        created_at: editData.created_at,
        deleted_at: editData.deleted_at,
        email: editData.email,
        firstname: editData.firstname,
        insightStatus: status ? false : true,
        interOrgStatus: editData.interOrgStatus,
        lastname: editData.lastname,
        platform: editData.platform,
        practitioner: editData.practitioner,
        quick_code: editData.quick_code,
        reportStatus: editData.reportStatus,
        updated_at: editData.updated_at
      })  
      // dispatch(noteRefs(new Date().getSeconds()))

      setTimeout(() => {
        setMessage(false)
      }, 2000);
    }).catch((err) => {
      setUpdatedData({})
      setMessage("Insight Not Updated")
      setMessageType("error");
    });

  };
  const handleSwitchInterorg = (status, id , editData) => {

    let s;
    if (status) {
      s = false
    } else {
      s = true
    }
    let json = JSON.stringify({
      interOrgStatus: s,
    })
    axiosInstance.current.put(
      `extorg/org/updateOrgHcp?doc_id=${id}&akou_id=${orgId}`, json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setMessage("Interorg Updated")
      setMessageType("success");
      setUpdatedData({
        account_status: editData.account_status,
        ak_id: editData.ak_id,
        created_at: editData.created_at,
        deleted_at: editData.deleted_at,
        email: editData.email,
        firstname: editData.firstname,
        insightStatus: editData.insightStatus,
        interOrgStatus: status ? false : true,
        lastname: editData.lastname,
        platform: editData.platform,
        practitioner: editData.practitioner,
        quick_code: editData.quick_code,
        reportStatus: editData.reportStatus,
        updated_at: editData.updated_at
      })  
      // dispatch(noteRefs(new Date().getSeconds()))

      setTimeout(() => {
        setMessage(false)
      }, 2000);
    }).catch((err) => {
      // dispatch(noteRefs(new Date().getSeconds()))

      setUpdatedData({})

      setMessage("InterorgF Not Updated")
      setMessageType("error");
    });

  };

  const handleSwitchReport = (status, id ,editData) => {

    let s;
    if (status) {
      s = false
    } else if (status === undefined) {
      s = false
    } else {
      s = true

    }

    console.log("statusstatus", s)
    let json = JSON.stringify({
      reportStatus: s,
    })
    axiosInstance.current.put(
      `extorg/org/updateOrgHcp?doc_id=${id}&akou_id=${orgId}`, json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setMessage("Report Updated")
      setMessageType("success");
      // dispatch(noteRefs(new Date().getSeconds()))
      setUpdatedData({
        account_status: editData.account_status,
        ak_id: editData.ak_id,
        created_at: editData.created_at,
        deleted_at: editData.deleted_at,
        email: editData.email,
        firstname: editData.firstname,
        insightStatus: editData.insightStatus,
        interOrgStatus: editData.interOrgStatus,
        lastname: editData.lastname,
        platform: editData.platform,
        practitioner: editData.practitioner,
        quick_code: editData.quick_code,
        reportStatus: status ? false : status===undefined ? false : true,
        updated_at: editData.updated_at
      }) 
      setTimeout(() => {
        setMessage(false)
      }, 2000);
    }).catch((err) => {
      // dispatch(noteRefs(new Date().getSeconds()))
      setUpdatedData({})

      setMessage("Report Not Updated")
      setMessageType("error");
    });

  };








  useEffect(() => {
    // Extract platform values from userData and create an array
    const platformValues = usersData.map((user) => user.platform);
    // Remove duplicates (if needed)
    const uniquePlatformValues = [...new Set(platformValues)];
    // Set the unique platform values to the state
    setEditPlatFormList(uniquePlatformValues);
  }, [usersData]);


  console.log("options", options)





  // useEffect(() => {
  //   if (options) {
  //     hanldeCall()
  //   }

  // }, [options]);

  console.log("optionssfeatureOptions", featureOptions)



  const columns = React.useMemo(
    () => {
      const baseColumns = [
        {
          Header: "",
          id: "1",
          headerClassName: "col-img",
          className: "col-img",
          accessor: "firstname",
          Cell: (row) => (
            <span>
              <label className="user-letter small">
                {row.value && row.value.trim() !== '' ? row.value.charAt(0).toUpperCase() : 'A'}
              </label>
            </span>
          ),
        },
        {
          Header: "User ID",
          accessor: "ak_id",
        },
        {
          Header: "User Name",
          accessor: "hcpName",
          Cell: (row) => {
            const { firstname, lastname } = row.row.original;

            let fullName = "Not Yet Joined";

            if (
              (firstname || lastname) &&
              firstname !== null && lastname !== null &&
              firstname !== undefined && lastname !== undefined
            ) {
              fullName = `${firstname || ""} ${lastname || ""}`.trim();
            }

            return <span>{fullName}</span>;
          },
        },
        {
          Header: "Email",
          accessor: "email",
          className: "text-lowercase",
        },
        {
          Header: "Account Status",
          accessor: "account_status",
          headerClassName: "col-acstatus",
          className: "col-acstatus",
          Cell: (row) => (
            <>
              {row.row.original.account_status === 1 ? (
                <span>Active</span>
              ) : (
                <span>InActive</span>
              )}
            </>
          ),
        },
        {
          Header: "Practitioner",
          accessor: "practitioner",
          headerClassName: "col-acstatus",
          className: "col-acstatus",
          Cell: (row) => (
            //  console.log("row", row.row.original.sno),
            <span>
              <Switch
                name={`on-off-switch${row.row.original.ak_id + '1'}`}
                tabIndex={1}
                checked={row.row.original.practitioner}
                onChange={(e) => handleSwitchChange(row.row.original.practitioner, row.row.original.ak_id, row.row.original)}
              />
            </span>
          ),
        },

        {
          Header: "Quick Code",
          accessor: "quick_code",
          headerClassName: "col-acstatus",
          className: "col-acstatus",
          Cell: (row) => (
            <>
              <span>{row.row.original.quick_code}</span>
            </>
          ),
        },
        {
          Header: "Platforms",
          accessor: "platform",
          headerClassName: "col-acstatus",
          className: "col-acstatus",
          Cell: (row) => (
            <span>
              <SelectPlatForm
                platFormData={platFormData}
                editPlatformList={row.row.original.platform}
                setOptions={setOptions}
                doctor_id={row.row.original.ak_id}
                org_id={orgId}
                ak_id={loginType.ak_id}
                email={row.row.original.email}
                orgName={orgName}
                platform_id={platform_id}
                class_style='org_user_hcp_table'
              />

            </span>
          ),
        },

      ];

      if (featureOptions.Insight) {
        baseColumns.splice(6, 0, {
          Header: "Insight",
          accessor: "Insight",
          headerClassName: "col-acstatus",
          className: "col-acstatus",
          Cell: (row) => (
            <span>
              <Switch
                name={`on-off-switch${row.row.original.ak_id + '2'}`}
                tabIndex={1}
                checked={row.row.original.insightStatus === undefined ? true : row.row.original.insightStatus}
                onChange={(e) => handleSwitchInsigth(row.row.original.insightStatus, row.row.original.ak_id,row.row.original)}
              />
            </span>
          ),
        });
      } if (featureOptions.Interorg_sharing) {
        baseColumns.splice(featureOptions.Insight ? 7 : 6, 0, {

          Header: "Interorg sharing",
          accessor: "Interorg_sharing",
          headerClassName: "col-acstatus",
          className: "col-acstatus",
          Cell: (row) => (
            //  console.log("row", row.row.original.sno),
            <span style={{ display: "block", width: "82px" }}>
              <Switch
                name={`on-off-switch${row.row.original.ak_id}`}
                tabIndex={1}
                checked={row.row.original.interOrgStatus}
                onChange={(e) => handleSwitchInterorg(row.row.original.interOrgStatus, row.row.original.ak_id,row.row.original)}
              />
            </span>
          ),

        })
      }

      if (featureOptions.Report) {
        baseColumns.splice(featureOptions.Report ? 7 : 6, 0, {

          Header: "Report",
          accessor: "Report",
          headerClassName: "col-acstatus",
          className: "col-acstatus",
          Cell: (row) => (

            <span>
              {console.log("row---original", row.row.original.reportStatus)}
              <Switch
                name={`on-off-switch${row.row.original.ak_id}+4`}
                tabIndex={1}
                checked={row.row.original.reportStatus === undefined ? true : row.row.original.reportStatus}
                onChange={(e) => handleSwitchReport(row.row.original.reportStatus, row.row.original.ak_id,row.row.original)}
              />
            </span>
          ),

        })
      }

      return baseColumns;
    },


    [selectedOption, featureOptions.Insight, featureOptions.Insight, featureOptions.Interorg_sharing, featureOptions.Report]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div className="tableContainer" id="rpScrollableDiv2323223">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={hasMore}

          scrollableTarget="rpScrollableDiv2323223"
          // loader={<TableSpinner />}
          loader={usersData.length > 9 && usersData.length !== totalData ? <TableSpinner /> : null}

        >
          <table {...getTableProps()} className="custom-tbl table5">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default OrganizationUserHcpTable;
