import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import useAxios from "../../../axiosinstance";
import actionStyles from "./TableActionItems.module.css";
import DeleteConfirmation from "../delete-confirmation/DeleteConfirmation";
import EditTag from "../../tags/TagEdit";
import EditPlan from "../../plans/EditPlan";
import toggleIcon from "../../../images/dots-blue.svg";
import viewIcon from "../../../images/eye-icon.svg";
import editIcon from "../../../images/edit-icon.svg";
import addIcon from "../../../images/user-plus.svg";
import invitePatientIcon from "../../../images/Invite Patient.svg";
import securityAdminIcon from "../../../images/Settings.svg";
import downloadLink from "../../../images/download-icon.svg";

import inviteHcpIcon from "../../../images/invite hcp.svg";
import inviteOrgAdminIcon from "../../../images/invite org admin.svg";
import checkIcon from "../../../images/check-icon.svg";
import deleteIcon from "../../../images/delete-icon.svg";
import navigateIcon from "../../../images/right-arrow-blue.svg";
import EditResourceType from "../../resource-types/EditResourceType";
import EditChannelDetails from "../../channels/edit-channel/EditChannelDetails";
import AddOrgAdmin from "../../organizations/AddOrgAdmin";
import Message from "../../custom/toster/Message";
import { useDispatch, useSelector } from 'react-redux'
import noteRef from "../../../redux/reducers/noteRef";
import { noteRefs } from "../../../redux/actions";
import ViewUsers from "../../my-users/ViewUsers";
import Viewdoc from "./Viewdoc";
import axios from "axios";
import { s3UrlLink } from "../../../utilities/S3Url";

const TableActionItems = ({
  orgData,
  rowData,
  specility,
  styleClass,
  onChange,
  value,
  viewAction,
  viewActionModal,
  editAction,
  editActionPopup,
  feedbackAction,
  addActionPopup,
  deleteAction,
  arrowAction,
  rowName,
  rowId,
  rowType,
  orgId,
  rowOrgId,
  setIsDelete,
  deleteId,
  noteid,
  notetitle,
  noteOrder,
  planName,
  hcp,
  patient,
  status,
  limit,
  duration,
  id,
  refresh,
  setRefresh,
  setRefresh3,
  refresh3,
  deltePlanId,
  deleteChannelId,
  type,
  channelname,
  role,
  all,
  organization_id,
  orglist,
  channelId,
  title,
  description,
  video_type,
  tags,
  channels,
  posted_by,
  enabled,
  videoId,
  created_on,
  video_key,
  typeId,
  publish_date,
  video_duration,
  video_link,
  sizeOfVideo,
  org_id,
  large_thumb,
  medium_thumb,
  addActionHcpPopup,
  addActionPatientPopup,
  addSecurityAdmin,
  orgListOrgName,
  orgViewAction,
  platFormData,
  langdata,
  platForm,
  editPlatformList,
  user_ak_id,
  user_first_name,
  user_last_name,
  user_email,
  user_type,
  user_organziation,
  user_status,
  doc_view,
  doc_view1,
  fileName,
  link_url,
  filelinkUrl,
  filetype,
  setOrgUserHcpList,
  orgUserHcpList,
  orgUserAdminList,
  setOrgUserPatientList,
  setOrgUserAdminList
}) => {
  // Set up some additional local state
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const apiMsgData = useSelector(state => state.mainReducer.apiMessageReducer.arrMessage);
  const apiMsgTypeData = useSelector(state => state.mainReducer.apiMessageTypeReducer.arrMessageType);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const axiosInstance = useAxios();
  const akTokenOrg = localStorage.getItem("AKTOKENORG")
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [displayViewModal, setDisplayViewModal] = useState(false);

  const [displayPlanEditModal, setDisplayPlanEditModal] = useState(false);
  const [displayResourceEditModal, setDisplayResourceEditModal] = useState(false);
  const [displayAddOrgAdminModal, setDisplayAddOrgAdminModal] = useState(false);
  const [displayEditChannelModal, setDisplayEditChannelModal] = useState(false);
  const [orgUsersRole, setOrgUsersRole] = useState("");
  const [addOrgDefault, setAddOrgDefault] = useState([]);
  const [titles, setTitles] = useState(notetitle)
  const [orders, setOrders] = useState(noteOrder)
  const [notesIds, seNoteIds] = useState(noteid)
  const [delteIds, setDeleteIds] = useState(deleteId)
  const [usersData, setUsersData] = useState([])
  const [deleteref, setDeleteref] = useState(false)
  const [updateRef, setUpdateRef] = useState(false)
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);

  const AKTOKENORG = localStorage.getItem("AKTOKENORG")

  // console.log("user_ak_id ,user_name , user_email , user_type , user_organziation , user_status" ,user_ak_id , user_name , user_email , user_type , user_organziation , user_status )

  const [show, setShow] = useState(false);
  console.log("viewAction, doc_view", viewAction, doc_view)

  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  // Handle the displaying of the modal based on type and id
  const showDeleteModal = () => {
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };


  // Handle the displaying of the modal based on type and id
  const showEditModal = async (field, number) => {
    console.log("rowType", rowType);
    if (rowType === "tags") {
      setDisplayEditModal(true);
    }
    if (rowType === "plans") {
      setDisplayPlanEditModal(true);
    }

    if (rowType === "resourceType") {
      setDisplayResourceEditModal(true);
    }
    if (rowType === "orgList") {
      setDisplayAddOrgAdminModal(true);
      setOrgUsersRole(number);
    }
    if (rowType === "channels") {
      if (all === true) {
        setDisplayEditChannelModal(true);
      } else {
        await axiosInstance.current.get(
          `extorg/admin/specific_channel?id=${channelId}&ak_id=${akTokenOrg}`
        ).then((res) => {
          console.log("response channel", res.data.data.users)
          setUsersData(res.data.data.users)
          setDisplayEditChannelModal(true);

        }).catch((err) => {
          console.log("Error", err)
        })
      }
    }

    if (rowType === "Video") {
      navigate("/edit-post-details", {
        state: {
          data: {
            title: title,
            description: description,
            video_type: video_type,
            tags: tags,
            channels: channels,
            posted_by: posted_by,
            enabled: enabled,
            created_on: created_on,
            videoId: videoId,
            video_key: video_key,
            typeId: typeId,
            publish_date: publish_date,
            duration: video_duration,
            link: video_link,
            video_size: sizeOfVideo,
            org_id: org_id,
            large_thumb: large_thumb,
            medium_thumb: medium_thumb
          }
        }
      });

    }
    if (rowType === "Live Event") {
      navigate(`/create-event/2`, {
        state: {
          data: {
            title: title,
            description: description,
            video_type: video_type,
            tags: tags,
            channels: channels,
            posted_by: posted_by,
            enabled: enabled,
            created_on: created_on,
            videoId: videoId,
            video_key: video_key,
            typeId: typeId,
            publish_date: publish_date,
            duration: video_duration,
            link: video_link,
            video_size: sizeOfVideo,
            org_id: org_id,
            large_thumb: large_thumb,
            medium_thumb: medium_thumb
          }
        }
      });
    }

    if (rowType === "usersList") {
      setDisplayViewModal(true)
    }

  };






  const handleDel = async () => {
    try {
      const result = await axiosInstance.current.delete(
        `extorg/org/delete_organization/${rowId}?ak_id=${login.ak_id}`
      );
      if (result) {
        setMessageType("success");
        setMessage("Organization Deleted");
        dispatch(noteRefs(!dataRefe))
        setTimeout(() => {
          setMessage(false)
        }, 3000);
      }
    } catch (err) {
      console.log(err.message);
      setMessage("Organization Not Delete");
      setMessageType("error");
    } finally {
      console.log("api response finally");
      setDisplayConfirmationModal(false);
    }
  };
  const handleUserDel = async () => {
    try {
      const result = await axiosInstance.current.delete(
        `extorg/admin/deleteUser/${login.ak_id}/${rowId}`
      );
      if (result) {
        setMessageType("success");
        setMessage("User Deleted");
        dispatch(noteRefs(!dataRefe))
        setTimeout(() => {
          setMessage(false)
        }, 3000);
      }
    } catch (err) {
      console.log(err.message);
      setMessage("User not delete");
      setMessageType("error");
    } finally {
      console.log("api response finally");
      setDisplayConfirmationModal(false);
    }
  };
  const handleGroupDel = async () => {

    let s;
    if (Number(rowOrgId) === 1) {
      s = 0
    } else {
      s = 0
    }
    try {
      const result = await axiosInstance.current.delete(
        `extorg/group/deleteGroup/${login.ak_id}/${rowId}?status=${-1}`
      );
      if (result) {
        setMessageType("success");
        setMessage("Group Deleted");
        dispatch(noteRefs(!dataRefe))
        setTimeout(() => {
          setMessage(false)
        }, 2500);
      }
    } catch (err) {
      console.log(err.message);
      setMessage("Group not delete");
      setMessageType("error");
    } finally {
      console.log("api response finally");
      setDisplayConfirmationModal(false);
    }
  };


  const handleShareDel = async () => {

    console.log("rowIdrowIdrowId", rowId)
    try {
      const result = await axiosInstance.current.delete(
        `extorg/sharing/deleteGroupSharing/${login.ak_id}/${rowId}?status=${-1}`
      );
      if (result) {
        setMessageType("success");
        setMessage("Share Deleted");
        dispatch(noteRefs(!dataRefe))
        setTimeout(() => {
          setMessage(false)
        }, 2500);
      }
    } catch (err) {
      console.log(err.message);
      setMessage("Share not delete");
      setMessageType("error");
    } finally {
      console.log("api response finally");
      setDisplayConfirmationModal(false);
    }

  }



  // Hide the modal
  const handleHideEditModal = () => {
    setDisplayEditModal(false);
  };

  const handleCloseShow = () => {
    setShow(false);
  };

  const handleViewUsersModal = () => {
    setDisplayViewModal(!displayViewModal)
  }


  // Handle the displaying of the modal based on type and id
  const showPlanEditModal = () => {
    setDisplayPlanEditModal(true);
  };

  // Hide the modal
  const handleHidePlanEditModal = () => {
    setDisplayPlanEditModal(false);
  };

  // Hide the modal
  const handleHideResourceEditModal = () => {
    setDisplayResourceEditModal(false);
  };

  // Hide the modal
  const handleHideAddOrgAdminModal = () => {
    setDisplayAddOrgAdminModal(false);
  };

  // Hide the modal
  const handleHideEditChannelModal = () => {
    setDisplayEditChannelModal(false);
  };


  // Handle the actual deletion of the item based on type
  const submitDelete = async () => {

    if (type === "1") {
      console.log("notesIds", noteid)
      const response = await axiosInstance.current.delete(
        `extorg/admin/removeTags?id=${noteid}&ak_id=${AKTOKENORG}`
      );
      if (response.status === 200) {
        setMessage("Tag Deleted")
        setMessageType("success");
        setTitles("")
        setOrders("")
        dispatch(noteRefs(!dataRefe))
        setTimeout(() => {
          setMessage(false)
        }, 3000);
      } else {
        setMessage("Tag Not Delete")
        setMessageType("error");
      }

      if (rowType === "patients") {
        // api code goes here
        console.log("==patient row deleted==");
      }
      setDisplayConfirmationModal(false);
    }

    if (type === "2") {
      console.log("notesIds", deltePlanId)
      const response = await axiosInstance.current.delete(
        `extorg/admin/deletePlan?id=${deltePlanId}&ak_id=${AKTOKENORG}`
      );
      if (response.status === 200) {
        setMessage("Plan Deleted")
        setMessageType("success");
        dispatch(noteRefs(!dataRefe))
        setTimeout(() => {
          setMessage(false)
        }, 2000);
      } else {
        setMessage("Plan Not Delete")
        setMessageType("error");
      }

      if (rowType === "patients") {
        // api code goes here
        console.log("==patient row deleted==");
      }
      setDisplayConfirmationModal(false);
    }

    if (type === "3") {
      const response = await axiosInstance.current.delete(
        `extorg/admin/deleteChannel?ak_id=${akTokenOrg}&id=${deleteChannelId}`
      );
      if (response.status === 200) {
        setMessage("Channel Deleted")
        setMessageType("success");
        dispatch(noteRefs(!dataRefe))
        setTimeout(() => {
          setMessage(false)
          // setRefresh(false)
        }, 2000);

      } else {
        setMessage("Channel Not Delete")
        setMessageType("error");
      }

      if (rowType === "patients") {
        // api code goes here
        console.log("==patient row deleted==");
      }
      setDisplayConfirmationModal(false);
    }

    if (type === "4") {
      const response = await axiosInstance.current.delete(
        `extorg/admin/postDelete/${videoId}`
      );
      if (response.status === 200) {
        setMessage("Video Deleted")
        setMessageType("success");
        dispatch(noteRefs(!dataRefe))
        setTimeout(() => {
          setMessage(false)
        }, 2000);
      } else {
        setMessage("Video Not Delete")
        setMessageType("error");
      }
      setDisplayConfirmationModal(false);

    }

    if (rowType === "orgList") {
      handleDel()
    }
    if (rowType === "usersList") {
      handleUserDel()
    }
    if (rowType === "groupsList") {
      handleGroupDel()
    }
    if (rowType === "shareList") {
      handleShareDel()
    }

  };

  useEffect(() => {
    if (orgId !== undefined) {
      setAddOrgDefault([{ value: orgId.toString(), label: rowOrgId }]);
    }
  }, [orgId]);

  const GetTask = (url, name) => {
    axios
      .get(s3UrlLink(url), {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        responseType: "blob",
      })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = name;
        a.click();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  console.log("langdatalangdata11", langdata)

  const editNavigate = () => {
    navigate(`/${rowData.edit_url}/${rowId}`, { state: { data: specility } });
    localStorage.setItem("specility", JSON.stringify(specility))
  }

  return (
    <>
      {apiMsgData && <Message message={apiMsgData} type={apiMsgTypeData} />}
      {message && <Message message={message} type={messageType} />}

      <div
        className={`action_large_dev custom_sort_dropdown ${actionStyles.action_large_dev}`}
      >
        <ul>
          {viewAction === "true" ? (
            <li>
              <Link to={`/${rowData.view_url}`}>
                <img className={`action_icons`} src={viewIcon} alt="Icon" />
              </Link>
            </li>
          ) : ""}

          {orgViewAction === true ? (
            <li>
              {/* <Link to={`/${rowData.edit_url}/${rowId}`} data-bs-toggle="tooltip" title={"View"}> */}
              <img className={`action_icons`} src={viewIcon} alt="Icon" onClick={editNavigate} />
              {/* </Link> */}
            </li>
          ) : ""}

          {editAction === "true" ? (
            <li>
              {/* <Link to={`/${rowData.edit_url}/${rowId}`} data-bs-toggle="tooltip" title={"Edit1"}> */}
              <img className={`action_icons`} src={editIcon} alt="Icon" onClick={editNavigate} data-bs-toggle="tooltip" title={"Edit"} style={{ cursor: "pointer" }} />
              {/* </Link> */}
            </li>
          ) : ""}
          {editActionPopup === "true" ? (
            <li>
              <span onClick={() => showEditModal("tags", 1)} data-bs-toggle="tooltip" title={"Edit"}>
                <img className={`action_icons`} src={editIcon} style={{ cursor: "pointer" }} alt="Icon" />
              </span>
            </li>
          ) : ""}
          {feedbackAction === "true" ? (
            <li>
              <Link to={`/${rowData.mark_url}`}>
                <img className={`action_icons`} src={checkIcon} alt="Icon" />
              </Link>
            </li>
          ) : ""}
          {addActionPopup === "true" ? (
            <li>
              <span onClick={() => showEditModal("addOrg", 2)} data-bs-toggle="tooltip" title={"Invite Org Admin"}>
                <img className={`action_icons`} src={inviteOrgAdminIcon} style={{ paddingTop: '2px', cursor: "pointer" }} alt="Icon" />
              </span>
            </li>
          ) : ""}
          {addActionHcpPopup === "true" ? (
            <li>
              <span onClick={() => showEditModal("addOrgHcp", 3)} data-bs-toggle="tooltip" title={"Invite HCP"}>
                <img className={`action_icons`} src={inviteHcpIcon} style={{ paddingTop: '2px', cursor: "pointer" }} alt="Icon" />
              </span>
            </li>
          ) : ""}
          {addActionPatientPopup === "true" ? (
            <li>
              <span onClick={() => showEditModal("addOrgPatient", 1)} data-bs-toggle="tooltip" title={"Invite Patient"}>
                <img className={`action_icons`} src={invitePatientIcon} style={{ paddingTop: '2px', cursor: "pointer" }} alt="Icon" />
              </span>
            </li>
          ) : ""}

          {addSecurityAdmin === "true" ? (
            <li>
              <span onClick={() => showEditModal("addSecretary", 4)} data-bs-toggle="tooltip" title={"Invite Secretary"}>
                <img className={`action_icons`} src={securityAdminIcon} style={{ paddingTop: '2px', cursor: "pointer" }} alt="Icon" />

              </span>
            </li>
          ) : ""}


          {deleteAction === "true" ? (
            <li>
              <span onClick={() => showDeleteModal("addOrgAdmin", 2)} data-bs-toggle="tooltip" title={"Delete"}>
                <img className={`action_icons`} src={deleteIcon} style={{ cursor: "pointer" }} alt="Icon" />
              </span>
            </li>
          ) : ""}

          {arrowAction === "true" ? (
            <li className={`${actionStyles.action_arrow}`}>
              <Link to={`/${rowData.view_url}`}>
                <img className={`action_icons`} src={navigateIcon} alt="Icon" />
              </Link>
            </li>
          ) : ""}

          {doc_view === "true" ? (
            <>
              <li>
                <span onClick={() => setShow(true)} data-bs-toggle="tooltip" title={"View"}>
                  <img className={`action_icons`} src={viewIcon} alt="Icon" />
                </span>
              </li>
              <li>
                <span onClick={() => GetTask(filelinkUrl, fileName)}>
                  <img
                    className={`action_icons`}
                    src={downloadLink}
                    alt="Icon"
                  />
                </span>
              </li>
            </>
          ) : ""}

          {doc_view1 === "true" ? (
            <>
              <li>
                <span onClick={() => setShow(true)} data-bs-toggle="tooltip" title={"View"}>
                  <img className={`action_icons`} src={viewIcon} alt="Icon" />
                </span>
              </li>
            </>
          ) : ""}





          {viewActionModal === "true" ? (
            <li>
              <span onClick={handleViewUsersModal} data-bs-toggle="tooltip" title={"View"} style={{ cursor: "pointer" }}>
                <img className={`action_icons`} src={viewIcon} alt="Icon" />
              </span>
            </li>
          ) : ""}




        </ul>
      </div>
      <div
        className={`action_small_dev text-end custom_sort_dropdown ${actionStyles.action_small_dev}`}
      >
        <Dropdown className={`custom_dropdown ${actionStyles.drop_down}`}>
          <Dropdown.Toggle
            className={actionStyles.dropdown_button}
            variant="default"
            id="dropdown-basic"
          >
            <span>
              <img
                className={`toggle_dots_img`}
                src={toggleIcon}
                alt="toggle"
              />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {orgViewAction === true ? (
              <Dropdown.Item>
                {/* <Link to={`/${rowData.edit_url}/${rowId}`} data-bs-toggle="tooltip" title={"View"}> */}
                <img className={`action_icons`} src={viewIcon} alt="Icon" onClick={editNavigate} data-bs-toggle="tooltip" title={"View"} style={{ cursor: "pointer" }} />
                View
                {/* </Link> */}
              </Dropdown.Item>
            ) : ""}
            {viewAction === "true" ? (
              <Dropdown.Item>
                <Link to={`/${rowData.view_url}`} data-bs-toggle="tooltip" title={"View"}>
                  <img className={`action_icons`} src={viewIcon} alt="Icon" />
                  View
                </Link>
              </Dropdown.Item>
            ) : ""}
            {editAction === "true" ? (
              <Dropdown.Item >


                {/* <Link to={`/${rowData.edit_url}/${rowId}`} data-bs-toggle="tooltip" title={"Edit"}> */}
                <img className={`action_icons`} src={editIcon} onClick={editNavigate} alt="Icon" data-bs-toggle="tooltip" title={"Edit"} />
                Edit
                {/* </Link> */}


              </Dropdown.Item>
            ) : ""}
            {editActionPopup === "true" ? (
              <Dropdown.Item >
                <span onClick={() => showEditModal()} data-bs-toggle="tooltip" title={"Edit"}>
                  <img className={`action_icons`} src={editIcon} alt="Icon" data-bs-toggle="tooltip" title={"Edit"} />
                  Edit
                </span>
              </Dropdown.Item>
            ) : ""}
            {feedbackAction === "true" ? (
              <Dropdown.Item>
                <Link to="/">
                  <img className={`action_icons`} src={checkIcon} alt="Icon" />
                  Feedback
                </Link>
              </Dropdown.Item>
            ) : ""}
            {addActionPopup === "true" ? (
              <Dropdown.Item>
                <span onClick={() => showEditModal("addOrg", 2)} data-bs-toggle="tooltip" title={"Invite Org Admin"}>
                  <img className={`action_icons`} src={inviteOrgAdminIcon} alt="Icon" />
                  Invite Admin
                </span>
              </Dropdown.Item>
            ) : ""}
            {addActionHcpPopup === "true" ? (
              <Dropdown.Item>
                <span onClick={() => showEditModal("addOrgHcp", 3)} data-bs-toggle="tooltip" title={"Invite HCP"}>
                  <img className={`action_icons`} src={inviteHcpIcon} alt="Icon" />
                  Invite HCP
                </span>
              </Dropdown.Item>
            ) : ""}
            {addActionPatientPopup === "true" ? (
              <Dropdown.Item>
                <span onClick={() => showEditModal("addOrgPatient", 1)} data-bs-toggle="tooltip" title={"Invite Patient"}>
                  <img className={`action_icons`} src={invitePatientIcon} alt="Icon" />
                  Invite Patient
                </span>
              </Dropdown.Item>
            ) : ""}
            {deleteAction === "true" ? (
              <Dropdown.Item>
                <span onClick={() => showDeleteModal("vegetable", 1)} data-bs-toggle="tooltip" title={"Delete"}>
                  <img className={`action_icons`} src={deleteIcon} alt="Icon" />
                  Delete
                </span>
              </Dropdown.Item>
            ) : ""}




            {addSecurityAdmin === "true" ? (
              <Dropdown.Item>
                <span onClick={() => showEditModal("addSecurity", 4)} data-bs-toggle="tooltip" title={"Secretary"}>
                  <img className={`action_icons`} src={securityAdminIcon} alt="Icon" />
                  Security Admin
                </span>
              </Dropdown.Item>
            ) : ""}

            {doc_view === "true" ? (
              <Dropdown.Item>
                <span onClick={() => setShow(true)} data-bs-toggle="tooltip" title={"View"}>
                  <img className={`action_icons`} src={viewIcon} alt="Icon" />
                  View
                </span>
              </Dropdown.Item>
            ) : ""}
            
            
            {doc_view1 === "true" ? (
              <Dropdown.Item>
                <span onClick={() => setShow(true)} data-bs-toggle="tooltip" title={"View"}>
                  <img className={`action_icons`} src={viewIcon} alt="Icon" />
                  View
                </span>
              </Dropdown.Item>
            ) : ""}

            {doc_view === "true" ? (
              <Dropdown.Item>
                <span onClick={() => GetTask(filelinkUrl, fileName)}>
                  <img
                    className={`action_icons`}
                    src={downloadLink}
                    alt="Icon"
                  />
                  Downlaod
                </span>
              </Dropdown.Item>
            ) : ""}





            {viewActionModal === "true" ? (
              <Dropdown.Item>
                <span onClick={handleViewUsersModal} data-bs-toggle="tooltip" title={"View"} style={{ cursor: "pointer" }}>
                  <img className={`action_icons`} src={viewIcon} alt="Icon" />
                  View
                </span>
              </Dropdown.Item>
            ) : ""}







          </Dropdown.Menu>
        </Dropdown>
      </div>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        message={notetitle}
        type={rowType}
        id={rowId}
      />
      <EditTag
        showModal={displayEditModal}
        hideModal={handleHideEditModal}
        type={rowType}
        id={rowId}
        noteTitle={notetitle}
        noteOrder={noteOrder}
        noteId={noteid}
        setDeleteref={setDeleteref}
        deleteref={deleteref}
      />

      <ViewUsers
        showModal={displayViewModal}
        hideModal={handleViewUsersModal}
        type={rowType}
        user_ak_id={user_ak_id}
        user_first_name={user_first_name}
        user_last_name={user_last_name}
        user_email={user_email}
        user_type={user_type}
        user_organziation={user_organziation}
        user_status={user_status}


      />

      <EditPlan
        showModal={displayPlanEditModal}
        hideModal={handleHidePlanEditModal}
        type={rowType}
        plan_name={planName}
        hcp={hcp}
        patient={patient}
        limit={limit}
        plan_duration={duration}
        setRefresh={setRefresh}
        refresh={refresh}
        id={id}
      />
      <EditResourceType
        showModal={displayResourceEditModal}
        hideModal={handleHideResourceEditModal}
        type={rowType}
        typeId={rowId}
      />
      <AddOrgAdmin
        showModal={displayAddOrgAdminModal}
        hideModal={handleHideAddOrgAdminModal}
        type={rowType}
        id={rowId}
        defaultData={addOrgDefault}
        userRoleType={orgUsersRole}
        orgNameSelected={orgListOrgName}
        organization_id={organization_id}
        platForm={platForm}
        platFormData={platFormData}
        languageData={langdata}
        editPlatformList={editPlatformList}
        orgData={orgData}
        specility={specility}
        setOrgUserHcpList={setOrgUserHcpList}
        orgUserHcpList={orgUserHcpList}
        setOrgUserPatientList={setOrgUserPatientList}
        setOrgUserAdminList={setOrgUserAdminList}
        orgUserAdminList={orgUserAdminList}
      />

      <EditChannelDetails
        showModal={displayEditChannelModal}
        hideModal={handleHideEditChannelModal}
        type={rowType}
        id={rowId}
        name={channelname}
        role={role}
        all={all}
        org_id={organization_id}
        orglist={orglist}
        channelId={channelId}
        usersData={usersData}

      />
      <Viewdoc
        showModal={show}
        handleCloseShow={handleCloseShow}
        link_url={link_url}
        filetype={filetype}
        filelinkUrl={filelinkUrl}
        fileName={fileName}
      />
    </>
  );
};

TableActionItems.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  styleClass: PropTypes.string,
  onChange: PropTypes.func,
};

TableActionItems.defaultProps = {
  value: "",
  label: "",
  styleClass: "",
  placeholder: "",
  rowData: "",
  viewAction: "",
  doc_view: "",
  doc_view1: "",
  editAction: "",
  editActionPopup: "",
  addActionPopup: "",
  feedbackAction: "",
  deleteAction: "",
  arrowAction: "",
  rowName: "",
  rowId: "",
  rowType: "",
};

export default TableActionItems;
