import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import InputField from "../../custom/inputfield/InputField";
import Dropdown from "../../custom/dropdown/Dropdown";
import Button from "../../custom/button/Button";
import { Validators } from "../../../utilities/Validator";
import useAxios from "../../../axiosinstance";
import useAxiosUpload from "../../../axiosinstanceUpload";
import { useSelector } from "react-redux";
import GoogleAddress from "../../custom/google-api-address/GoogleAddress";
import deleteIcon from "../../../images/delete-red.svg";
import editIcon from "../../../images/edit-icon.svg";
import "./CreateOrganization.style.css";
import Message from "../../custom/toster/Message";
import Select from 'react-select';
import icon_info from '../../../images/info-icon.svg';
import Checkbox from "../../custom/checkbox/Checkbox";
import UploadTemplate from "./UploadTemplate";
import fileUploadStyle from "../../My-Document/FileUpload.module.css";
import FileUploadIcon from "../../../images/fileupload.svg";
import { s3UrlLink } from "../../../utilities/S3Url";
import DeleteIcon from "../../../images/deleteLogo.svg";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";

function AddOrganization() {
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );

  const allPlatForms = useSelector(
    (state) =>
      state.mainReducer.platFormData.platforms
  );

  console.log("allPlatForms", allPlatForms)

  const axiosInstance = useAxios();

  const axiosInstanceUpload = useAxiosUpload();
  const [items, setItems] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [streetAdd, setStreetAdd] = useState("");
  const [editMode, setEditMode] = useState(null);
  const [city, setCity] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [postCode, setPostCode] = useState("");
  const [postCodeErr, setPostCodeErr] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [featureErrorMessage, setFeatureErrorMessage] = useState("");
  const [keyID, setKeyID] = useState(1);
  const [addressList, setAddressList] = useState([]);
  const [locationError, setLocationError] = useState(false);
  const [addressError, setaddressError] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [platFormData, setPlatFormData] = useState([]);
  const [planList, setPlanList] = useState("");
  const [platFormList, setPlatFormList] = useState([]);
  const [isHidden, setIsHidden] = useState(false);
  const [paltformErr, setPaltformErr] = useState(false);
  const [countrydata, setCountryLists] = useState([]);
  const [country, setcountry] = useState("");
  const [disble, setDisable] = useState(false)
  const [isphonenumber, setIsPhonenumber] = useState(false);
  const [autoJoinPatient, setAutoJoinPatient] = useState(false)
  const orgNameRef = useRef();
  const streetAddRef = useRef();
  const cityRef = useRef();
  const stateValueRef = useRef();
  const postCodeRef = useRef();
  const phoneRef = useRef();
  const planRef = useRef();
  const platformRef = useRef();
  const countryRef = useRef()
  const autoJoinRef = useRef()
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState([]);

  const [logoUrl, setLogoUrl] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');

  const toggleVisibility = (e) => {
    e.preventDefault();
    setIsHidden(!isHidden);
  };

  const changeOrgName = (event) => {
    setOrgName(event);
  };
  const changeStreetAdd = (event) => {
    setStreetAdd(event);
    setaddressError(false);
  };
  const changeCity = (event) => {
    setCity(event);
  };
  const changeStateValue = (event) => {
    setStateValue(event);
  };
  const changePostCode = (event, err) => {
    setPostCode(event.replace(/[^0-9]/g, ''));
    setPostCodeErr(err);
  };
  const changePhone = (event, err) => {
    setPhone(event.replace(/[^0-9]/g, ''));
    setPhoneErr(err);
  };
  const [checkedItems, setCheckedItems] = useState({
    "user_form_shoulder": false,
    "user_form_hip": false,
    "user_foot_ankle_form": false,
    "user_elbow_form": false,
    "user_knee_form": false,
    "user_hand_wrist_form": false,
    "general_form": false,
  });
  const [checkedFeatureItems, setCheckedFeatureItems] = useState({
    "Akunah Med": true,
    "Reflect": true,
    "Pacs": true,
    "Radiology": true,
    "Pathology": true,
    "Insight": false,
    "Interorg_sharing": false,
    "Report": false
  });

  const planOptions = planData.map((item) => {
    return { value: item.p_id, label: item.p_nm };
  });

  const platFormOptions = platFormData.map((item) => {
    return { value: item.plat_id, label: item.name };
  });
  const selectedPlatforms = selectedOption.map(item => item.value);

  const json = JSON.stringify({
    org_name: orgName,
    address: addressList,
    approved_status: 1,
    features: [checkedFeatureItems],
    specility: [checkedItems],
    plan_id: [planList],
    platforms: selectedPlatforms,
    country_code: Number(country),
    patient_check: autoJoinPatient,
    logo: uploadedImageUrl
  });
  const handleSave = async () => {

    setDisable(true)
    try {

      console.log("jsonjsonjsonjsonjson", json)
      const result = await axiosInstance.current.post(
        `extorg/org/create_organization?ak_id=${login.ak_id}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 201) {
        setMessageType("success");
        setMessage("Organization successfully created");
        setTimeout(() => {
          navigate("/organization-list");
          setMessage(false);
        }, 2500);
      }
    } catch (err) {
      setMessage(err.message);
      setMessageType("error");
      setDisable(false)
    } finally {
      console.log("api response finally");
    }
  };

  const selectedValue = 1; // This is the selected value
  const selectedLabelObj = selectedOption.find(item => item.value === selectedValue);
  const selectedLabel = selectedLabelObj ? selectedLabelObj.label : '';



  const handleSelection = (selectedValues) => {
    setSelectedOption(selectedValues)
    setErrorMessage("");
    setAutoJoinPatient(false)
    if (selectedValues.length === 1) {
      if (selectedValues[0].label === "MED") {
        setCheckedItems({
          "user_form_shoulder": false,
          "user_form_hip": false,
          "user_foot_ankle_form": false,
          "user_elbow_form": false,
          "user_knee_form": false,
          "user_hand_wrist_form": false,
          "general_form": false,
        });
      }
    }
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    const numberOfCheckedItems = Object.values(checkedItems).filter(Boolean)
      .length;
    const numberOfCheckedFeatureItems = Object.values(
      checkedFeatureItems
    ).filter(Boolean).length;
    if (selectedLabel === "CLINICIAN") {
      if (numberOfCheckedItems === 0) {
        setErrorMessage("Please select at least one option");
      } else {
        setErrorMessage("");
      }
      if (numberOfCheckedFeatureItems === 0) {
        setFeatureErrorMessage("Please select at least one option");
      } else {
        setFeatureErrorMessage("");
      }
    }

    if (!orgName) {
      orgNameRef.current.errUpdate();
    }
    console.log("country", country)
    if (!country) {
      countryRef.current.errUpdate()
    }

    // if (!streetAdd) {
    //   setaddressError(true);
    // }
    // if (!city) {
    //   cityRef.current.errUpdate();
    // }
    // if (!stateValue) {
    //   stateValueRef.current.errUpdate();
    // }
    // if (!postCode) {
    //   postCodeRef.current.errUpdate();
    // }
    // if (phone.length<10 && !/^\d{10}$/.test(phone) ) {

    //   phoneRef.current.errUpdate();

    //   return;
    // }
    if (selectedPlatforms.length === 0) {
      setPaltformErr(true)
    }
    if (!planList) {
      planRef.current.errUpdate();
    }
    // if (addressList.length === 0) {
    //   setLocationError(true);
    //   setIsHidden(false);
    // }
    // alert("ONE")
    if (
      !orgName ||
      selectedPlatforms.length === 0 ||
      !planList ||
      // addressList.length === 0 ||
      (selectedLabel === "CLINICIAN" &&
        (numberOfCheckedItems === 0 || numberOfCheckedFeatureItems === 0))
      // postCodeErr ||
      // phoneErr
    ) {
      // alert(1);
    } else if (orgName && country &&
      selectedPlatforms.length !== 0 &&
      planList) {
      //  alert("alert working");
      console.log("comming 5")
      handleSave();
    }
  };

  console.log("paltformErrpaltformErr", paltformErr)
  const [offset, setOffset] = useState(10);
  const [limit, setLimit] = useState(10);
  const customUserStrings = {
    selectSomeItems: 'Platforms...',
    allItemsAreSelected: `${platFormList.length} Platform selected`,
  };

  const featuresData = [
    {
      label: "Akunah Med",
      value: "Akunah Med",
      locked: false,
    },
    {
      label: "Reflect",
      value: "Reflect",
      locked: false,
    },
    {
      label: "Pacs",
      value: "Pacs",
      locked: false,
    },
    {
      label: "Radiology",
      value: "Radiology",
      locked: false,
    },
    {
      label: "Pathology",
      value: "Pathology",
      locked: false,
    },
    {
      label: "Insight",
      value: "Insight",
      locked: false,
    },
    {
      label: "Interorg sharing",
      value: "Interorg_sharing",
      locked: false,
    },
    {
      label: "Report",
      value: "Report",
      locked: false,
    },
  ];

  const [form, setItems2] = useState([]);
  const [featureForm, setFeatureForm] = useState(featuresData);

  let a = form.filter((items) => items.name);
  let b = featureForm.filter((items) => items.value);

  const options = a.map((item) => {
    return {
      id: item.value,
      label: item.label,
      value: item.name,
      locked: item.locked,
      active: item.active,
    };
  });

  const featureOptions = b.map((item) => {
    return {
      id: item.value,
      label: item.label,
      value: item.value,
      locked: item.locked,
      active: item.active,
    };
  });
  const handleChange1 = (e) => {
    console.log("seletedLAVEL", selectedLabel)
    setCheckedItems({
      ...checkedItems,
      [e.target.value]: e.target.checked,
    });
  };
  const handleChange2 = (e) => {

    setCheckedFeatureItems({
      ...checkedFeatureItems,
      [e.target.value]: e.target.checked,
    });
  };

  const handlePlatFormListData = (e) => {
    setPlatFormList(e);
  };

  const handlePlanListData = (e) => {
    setPlanList(e);
  };

  const resetLocation = () => {
    setStreetAdd("");
  };

  useEffect(() => {
    // setshow1(false);
    const getSpecialityData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/org/get_specilaity?ak_id=${login.ak_id}`
        );
        setItems2(response.data.data);
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getSpecialityData();
  }, []);

  useEffect(() => {
    // setshow1(false);
    const getPlatFormData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/getPlatform`
        );
        setPlatFormData(response.data.data);
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getPlatFormData();
  }, []);

  useEffect(() => {
    // setshow1(false);
    const getPlansData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/plans?ak_id=${login.ak_id}`
        );
        setPlanData(response.data.data);
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getPlansData();
  }, []);

  const addLocations = (e) => {
    e.preventDefault();
    // if (!streetAdd) {
    //   setaddressError(true);
    // }
    // if (!city) {
    //   cityRef.current.errUpdate();
    // }
    // if (!stateValue) {
    //   stateValueRef.current.errUpdate();
    // }
    // if (!postCode) {
    //   postCodeRef.current.errUpdate();
    // }
    // if (!phone) {
    //   phoneRef.current.errUpdate();
    // }
    if (!streetAdd || !phone || !stateValue || !postCode || !city) return;
    if (editMode !== null) {
      // Update existing address in addressList
      const updatedAddressList = [...addressList];
      updatedAddressList[editMode] = {
        street_address: streetAdd,
        phone_no: phone,
        postal_code: postCode,
        city_suburb: city,
        state: stateValue,
      };
      setAddressList(updatedAddressList);
    } else {
      // Add new address to addressList
      const newAddress = {
        street_address: streetAdd,
        phone_no: phone,
        postal_code: postCode,
        city_suburb: city,
        state: stateValue,
      };
      setAddressList([...addressList, newAddress]);
    }

    // Reset form fields and errors
    setLocationError(false);
    resetLocation();
    setStreetAdd("");
    setCity("");
    setStateValue("");
    setPostCode("");
    setPhone("");
    setEditMode(null); // Reset edit mode
  };
  const handleRemoveItem = (index) => {
    setAddressList(addressList.filter((_, i) => i !== index));
  };
  const handleEditItem = (index) => {
    console.log("index", index);
    setEditMode(index);
    const selecteEditAddress = addressList[index];
    setStreetAdd(selecteEditAddress.street_address);
    setCity(selecteEditAddress.city_suburb);
    setStateValue(selecteEditAddress.state);
    setPostCode(selecteEditAddress.postal_code);
    setPhone(selecteEditAddress.phone_no);
    setIsHidden(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  };


  const handleCountry = (e) => {
    setcountry(e);
  };

  const countryList = async () => {
    try {
      const res = await axiosInstance.current.get(
        "extorg/admin/country_list"
      );
      if (res.status === 200) {
        setCountryLists(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    countryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("platFormList", selectedPlatforms)



  const handleAutoJoinPatients = (e) => {
    setAutoJoinPatient(e)
  }

  const validateImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.height > 72 || img.width > 248) {
          reject("Please upload a logo with dimensions 72px height and 248px width.");
        } else {
          resolve();
        }
      };
      img.onerror = () => {
        reject("Invalid image file.");
      };
    });
  };

  const handleFileUpload = async (file) => {
    if (!file) return;

    const formData = new FormData();
    formData.append("profile_pic", file);
    try {
      const response = await axiosInstanceUpload.current.post(
        `extglobal/updateTemplate?ak_id=${login.ak_id}`,
        formData
      );

      if (response && response.data) {
        setUploadedImageUrl(response.data.url);
        setMessage("File uploaded successfully!");
        setMessageType("success");

        setTimeout(() => {
          setMessage(false)
        }, 2000);
        
      }
    } catch (err) {
      setMessage(err.response?.data?.message || "An error occurred.");
      setMessageType("error");
      setTimeout(() => {
        setMessage(false)
      }, 2000);
      
    }finally{
      
    }
  };


  const handleRemoveImage = () => {
    setUploadedImageUrl("");
    setMessage("");
    setMessageType("");
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // await validateImageDimensions(file);
        handleFileUpload(file);
      } catch (error) {
        setMessage(error);
        setMessageType("error");
      }
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file) {
      try {
        // await validateImageDimensions(file);
        handleFileUpload(file);
      } catch (error) {
        setMessage(error);
        setMessageType("error");
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };


  return (
    <div className="add_org_screen">
      {message && <Message message={message} type={messageType} />}

      <div className="">
        <div className="mt_30">
          <form autoComplete="off">
            <div className="panel pt_35 pd_25">
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  <InputField
                    label=""
                    value={orgName}
                    ref={orgNameRef}
                    type="text"
                    placeholder="*Organization Name"
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                    onChange={changeOrgName}
                    styleClass="mxw_100 br_10 mh_50"
                  />
                </div>
              </div>

              <div className="text-end">
                <Button
                  onClick={toggleVisibility}
                  value={`${isHidden ? "+ Add Location" : "Hide Location"}`}
                  buttonStyle="btn_fill w_180 mb_20"
                />
              </div>
              {/* <div className="">
                {locationError && (
                  <span className="text-danger">
                    Click Add Location to add at least one location
                  </span>
                )}
              </div> */}
              {!isHidden && (
                <>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 mb_10">
                      <GoogleAddress
                        //  label={'address'}
                        onAddressChange={changeStreetAdd}
                        valid={addressError}
                        value={streetAdd}
                        setPostCode={setPostCode}
                        setCity={setCity}
                        resetLocation={resetLocation}
                        styleClass={"mxw_100 br_10 mh_50"}
                      ></GoogleAddress>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 mb_10">
                      <InputField
                        label=""
                        value={city}
                        ref={cityRef}
                        type="text"
                        placeholder="City/Subrub"
                        // validators={[
                        //   {
                        //     check: Validators.required,
                        //     message: "This field is required",
                        //   },
                        // ]}
                        onChange={changeCity}
                        styleClass="mxw_100 br_10 mh_50"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 mb_10">
                      <InputField
                        label=""
                        value={stateValue}
                        ref={stateValueRef}
                        type="text"
                        placeholder="State"
                        // validators={[
                        //   {
                        //     check: Validators.required,
                        //     message: "This field is required",
                        //   },
                        // ]}
                        onChange={changeStateValue}
                        styleClass="mxw_100 br_10 mh_50"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 mb_10">
                      <InputField
                        label=""
                        value={postCode}
                        ref={postCodeRef}
                        type="text"
                        placeholder="Post Code"
                        // validators={[
                        //   {
                        //     check: Validators.required,
                        //     message: "This field is required",
                        //   },
                        //   {
                        //     check: Validators.spacial,
                        //     message: "Invalid character",
                        //   },
                        //   {
                        //     check: Validators.postcode,
                        //     message: "Fields only contain 10 character",
                        //   },
                        // ]}
                        onChange={changePostCode}
                        styleClass="mxw_100 br_10 mh_50"
                      />
                    </div>


                    <div className="col-md-6 col-sm-6 mb_10">
                      <InputField
                        label=""
                        value={phone}
                        ref={phoneRef}
                        type="text"
                        placeholder="Phone"
                        // validators={[
                        //   {
                        //     check: Validators.required,
                        //     message: "This field is required",
                        //   },
                        //   {
                        //     check: Validators.number,
                        //     message: "This field can only contain numbers.",
                        //   },
                        //   {
                        //     check: Validators.maxmobile,
                        //     message: "Maximum 10 Numbers are allowed.",
                        //   },
                        // ]}
                        onChange={changePhone}
                        styleClass="mxw_100 br_10 mh_50"
                      />
                    </div>



                  </div>
                  <div className="row">
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6 text-right">
                      {editMode !== null ? (
                        <Button
                          onClick={addLocations}
                          value="Update"
                          buttonStyle="btn_fill mr_20 w_180"
                        />
                      ) : (
                        <Button
                          onClick={addLocations}
                          value="Save"
                          buttonStyle="btn_fill mr_20 w_180"
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div>
                  {addressList.length !== 0 && (
                    <div className="mt-3 mb-3 selected_plans_table">
                      <table className="table m-0" style={{ maxWidth: "100%" }}>
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Address</th>
                            <th>Subrub</th>
                            <th>State</th>
                            <th>Postal Code</th>
                            <th>Phone</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {addressList.map((itemval, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{itemval.street_address}</td>
                                <td>{itemval.city_suburb}</td>
                                <td>{itemval.state}</td>
                                <td>{itemval.postal_code}</td>
                                <td>{itemval.phone_no}</td>
                                <td>
                                  <img className="ml_15" src={editIcon} alt="icon" onClick={() => handleEditItem(index)} />
                                  <img
                                    className="ml_15"
                                    src={deleteIcon}
                                    alt="icon"
                                    onClick={() => handleRemoveItem(index)}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>


            <div className="panel pt_35 pd_25">
              <label>Branding</label>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  <div className="file_upload">
                    {!uploadedImageUrl ? (
                      <div
                        className={`${fileUploadStyle.drop_zone}`}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                        <div>
                          <img src={FileUploadIcon} alt="File Upload Icon" />
                          <h4 className="mt-2 f_600">Upload your brand logo here</h4>
                          {/* <p className={`${fileUploadStyle.small}`}>
                            or browse files on your computer.
                          </p> */}
                          <p
                            className={`${fileUploadStyle.small}`}
                            style={{
                              fontSize: "14px",
                              fontWeight: 400,
                            }}
                          >
                            For best results logo <br />dimensions should be <span class="browse-template">248 x 72 px</span>
                          </p>
                          <input
                            type="file"
                            onChange={handleFileChange}
                            name="ufile"
                            id="upload"
                            style={{ display: "none" }}
                            accept=".png,.jpeg,.pdf,.jpg"
                          />
                          <label
                            htmlFor="upload"
                            className={`${fileUploadStyle.browserfilebtn}`}
                          >
                            Browse Files
                          </label>
                        </div>
                      </div>
                    ) : (
                     
                      
<div
                        className={`${fileUploadStyle.drop_zone}`}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                      
                        <div>
                          <img
                            src={s3UrlLink(uploadedImageUrl)}
                            alt="Uploaded"
                            style={{
                              height: "72px",
                              width: "248px",
                              objectFit: "cover",
                              border: "1px solid #ddd",
                              borderRadius: "4px",
                              marginBottom: "10px",
                            }}

                          />
                          <h4 className="mt-2 f_600">Upload your brand logo here</h4>
                          {/* <p className={`${fileUploadStyle.small}`}>
                          or browse files on your computer.
                        </p> */}
                          <p
                            className={`${fileUploadStyle.small}`}
                            style={{
                              fontSize: "14px",
                              fontWeight: 400,
                            }}
                          >
                            For best results logo <br />dimensions should be <span class="browse-template">248 x 72 px</span>
                          </p>
                          <input
                            type="file"
                            onChange={handleFileChange}
                            name="ufile"
                            id="upload"
                            style={{ display: "none" }}
                            accept=".png,.jpeg,.pdf,.jpg"
                          />
                          <label
                            htmlFor="upload"
                            className={`${fileUploadStyle.browserfilebtn}`}
                          >
                            Browse Files
                          </label>
                        </div>
                        {/* <button className="btn btn-danger" onClick={handleRemoveImage}>
                          <img src={DeleteIcon} className="delete_pp_icon" alt="icon" /> Remove
                        </button> */}

                      </div>
                     
                     
                    )}
                    {/* {message && (
                    <p
                                                  className={
                                                      messageType === "error" ? "text-danger" : "text-success"
                                                  }
                                              >
                                                  {message}
                                              </p>
                                          )} */}
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 mb_10 text-end">
                  {/* <UploadTemplate logoUrl={logoUrl} setLogoUrl={setLogoUrl}/> */}
                </div>
              </div>
              <div className="text-end">

              </div>
            </div>


            <div className="panel pt_35 pd_25">
              <div className="row">
                <div className="col-md-6 col-sm-6 pt_10">
                  {/* <label>Allowed platforms *</label>
                  <MultiSelect
                    options={platFormOptions}
                    value={platFormList}
                    onChange={handlePlatFormListData}
                    displayValue="title"
                    className="multi_select_main"
                    overrideStrings={customUserStrings}
                            // showCheckbox
                  /> */}
                  <label>Allowed platforms *</label>
                  <Select
                    value={selectedOption}
                    isMulti
                    onChange={handleSelection}
                    options={platFormOptions}
                    className="multi_select_drp_select"
                    placeholder="Select Platforms"
                  />
                  {
                    selectedPlatforms.length === 0 && (
                      <>
                        {paltformErr && <span className="text-danger">This field is required</span>}
                      </>
                    )
                  }
                  <div className="mt-3">
                    <label>Organization Country *</label>
                    <img className="c_pointer ms-2 me-2" src={icon_info} data-bs-toggle="tooltip" data-bs-placement="top"
                      title="Once the country is set it cannot be modified later" alt='icon' />
                    <Dropdown
                      data={countrydata}
                      label=""
                      styleClass="language_drp_dwn"
                      value={country}
                      placeholder="Select Country"
                      onChange={(e) => handleCountry(e)}
                      ref={countryRef}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    // showCheckbox
                    />
                  </div>



                  {
                    selectedPlatforms.includes(1) && !selectedPlatforms.includes(2) ? (
                      <div className="mt-4">
                        {/* <label>Auto Join Patients To MED Channles ?</label> */}
                        <Checkbox
                          label={"Auto Join Patients To MED Channels?"}
                          selected={autoJoinPatient}
                          onChange={handleAutoJoinPatients}
                          styleClass="custom_check_box tick f_400 large"
                          id="feature_external_gp"
                        />
                      </div>
                    ) : null
                  }


                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <div
                    className="text-right mb_10"
                    style={{ position: "relative" }}
                  >
                    <Button
                      onClick={() => navigate("/plans")}
                      value="Go to Plan"
                      buttonStyle="btn_fill add_org__btn"
                    />
                  </div>
                  <Dropdown
                    data={planOptions}
                    label="Assign a Plan*"
                    value={planList}
                    onChange={handlePlanListData}
                    placeholder="Select Plan"
                    styleClass="br_10 mxw_100 mb_20"
                    ref={planRef}
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            {selectedLabel === "CLINICIAN" ?
              <div className="panel pt_35 pd_25">
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <div className="mb_15">
                      <label>{"Accessible features *"} </label>

                      <div className="row">
                        {featureOptions.map((option) => (
                          <div className="col-6">
                            <div
                              className="form-group custom_check_box tick"
                              key={option.id}
                            >
                              <input
                                className="me-2"
                                type="checkbox"
                                value={option.value}
                                checked={checkedFeatureItems[option.value]}
                                disabled={option.locked}
                                onChange={handleChange2}
                                id={"feature" + option.id}
                                name={"feature" + option.id}
                              />
                              <label className="me-4" for={"feature" + option.id}>
                                {" "}
                                {option.label}
                              </label>
                            </div>
                          </div>
                        ))}

                        {featureErrorMessage && (
                          <p style={{ color: "red" }}>{errorMessage}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <div className="mb_15">
                      <label>{"Treatment specialities *"} </label>

                      <div className="row">
                        {options.map((option) => (
                          <div className="col-6">
                            <div
                              className="form-group custom_check_box tick"
                              key={option.id}
                            >
                              <input
                                className="me-2"
                                type="checkbox"
                                value={option.value}
                                checked={checkedItems[option.value]}
                                disabled={option.locked}
                                onChange={handleChange1}
                                id={"spec" + option.id}
                                name={"spec" + option.id}
                              />
                              <label className="me-4" for={"spec" + option.id}>
                                {" "}
                                {option.label}
                              </label>
                            </div>
                          </div>
                        ))}

                        {errorMessage && (
                          <p style={{ color: "red" }}>{errorMessage}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : ""}

            <div className="panel pt_35 pd_25">
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10"></div>
                <div className="">
                  <div className="col-sm-12 col-md-12 text-center">
                    <Button
                      onClick={() => navigate("/organization-list")}
                      value="Back"
                      buttonStyle="btn_fill "
                      style={{ padding: "0 40px" }}
                    />
                    <Button
                      onClick={handleSubmit}
                      enable={disble}
                      value="Save"
                      buttonStyle="btn_fill ms-4 clk_btn_disbled"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <br />
        </div>
      </div>
    </div>
  );
}

export default AddOrganization;
