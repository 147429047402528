import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import Dropdown from "../custom//dropdown/Dropdown";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import {
  Validators,
  spacialCharReplace,
  validateInput,
} from "../../utilities/Validator";
import { noteRefs } from "../../redux/actions";
import {
  apiResponseMsgData,
  apiResponseMsgTypeData,
} from "../../redux/actions";
import useTranslation from "../customHooks/translations";
import Select from "react-select";
import Message from "../custom/toster/Message";
import AlertImage from "../../images/alert.svg.png";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useLocation } from "react-router-dom";
import PlusIcon from "./plus.png"
import deleteIcon from "../../images/delete-icon.svg";
const AddOrgAdmin = ({
  orgData,
  showModal,
  hideModal,
  specility,
  confirmModal,
  id,
  type,
  defaultData,
  userRoleType,
  orgNameSelected,
  organization_id,
  platForm,

  // platFormData,
  editPlatformList,
  setOrgUserHcpList,
  orgUserHcpList,
  orgUserAdminList,
  setOrgUserPatientList,
  setOrgUserAdminList
}) => {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [add, setAdd] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [organization, setOrganization] = useState("");
  const [lan, setLan] = useState("");
  const [speciality2, setSpeciality2] = useState([]);
  const [email, setEmail] = useState([]);
  // const [message, setMessage] = useState([]);
  const [emailListError, setEmailListError] = useState(false);
  const [platError, setPlatError] = useState(false);
  const platFormData = useSelector(
    (state) => state.mainReducer.platFormData.platforms
  );

  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();

  const [readOnly, setReadOnly] = useState(true);
  const [subLineTxt, setSubLineTxt] = useState("");
  const [dearLineTxt, setDearLineTxt] = useState("");
  const [congratsLine1Txt, setCongratsLine1Txt] = useState("");
  const [platformtypeTxt, setPlatformtypeTxt] = useState("");
  const [congratsLine2Txt, setCongratsLine2Txt] = useState("");
  const [hereLineTxt, setHereLineTxt] = useState("");
  const [signinLinetitleTxt, setSigninLinetitleTxt] = useState("");
  const [signinLine1Txt, setSigninLine1Txt] = useState("");
  const [platformurl, setPlatformurl] = useState("");
  const [platformurlTxt, setPlatformurlTxt] = useState("");
  const [signinLine2Txt, setSigninLine2Txt] = useState("");
  const [signupLinetitleTxt, setSignupLinetitleTxt] = useState("");
  const [signupLine1Txt, setSignupLine1Txt] = useState("");
  const [signupLine21Txt, setSignupLine21Txt] = useState("");
  const [signupLine22Txt, setSignupLine22Txt] = useState("");
  const [signupLine3Txt, setSignupLine3Txt] = useState("");
  const [signupLine4Txt, setSignupLine4Txt] = useState("");
  const [experienceLineTxt, setExperienceLineTxt] = useState("");
  const [thrilledLineTxt, setThrilledLineTxt] = useState("");
  const [regardsLineTxt, setRegardsLineTxt] = useState("");
  const [teamLineTxt, setTeamLineTxt] = useState("");

  const [selectedOption, setSelectedOption] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [errorEmailData, setErrorEmailData] = useState([]);
  const [emailSuccesData, setEmailSuccessData] = useState([]);
  const [platFormallData, setplatFormallData] = useState([]);

  const [emailcheck, setEmailcheck] = useState(false);
  const [emailError, setEmailerror] = useState(false);
  const [sameEmailError, setSameEmailError] = useState(false);
  const language = useSelector((state) => state.mainReducer.languageData.arr);

  let responseData = [];
  let responseData2 = [];

  const location = useLocation();
  const receivedData = localStorage.getItem("specility") || "";
  console.log("receivedData", receivedData);
  let orgSpecc = specility
    ? specility
    : receivedData === "" || receivedData === "undefined"
      ? ""
      : JSON.parse(receivedData);
  let clinics = new URL("https://clinicians.akunah.com");
  let meds = new URL("https://med.akunah.com");
  let both_url = `${clinics.href} ${" "} ${meds.href}`;
  console.log(both_url, "both_url");
  console.log(orgNameSelected, "orgNameSelected");
  const emailRef = useRef();
  const orgNameRef = useRef();
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const translation = useTranslation();
  const dispatch = useDispatch();
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [disble, setDisable] = useState(false);

  const handleCloseEdit = (e) => {
    hideModal(false);
    setEmailcheck(false);
    setEmailerror(false);
    setEmail([]);
    setAdd("");
    setSelectedOption([]);
    setEmailListError(false);
    setPlatError(false);
    setErrorEmailData([]);
    setEmailSuccessData([]);
    setSpeciality2([]);
  };

  const keyNameChange = (e) => {
    setAdd(e);
    setEmailListError(false);
    setSameEmailError(false);
  };
  const keyFNameChange = (e) => {
    setFname(e);
  };
  const keyLNameChange = (e) => {
    setLname(e);
  };

  const handleLanchange = (e) => {
    setLan(e);
  };
  const newPlatFormData = [
    {
      label: "MED",
      value: 2,
    },
    {
      label: "CLINICIAN",
      value: 1,
    },
  ];

  useEffect(() => {
    if (userRoleType === 1) {
      setSubLineTxt(translation.Invite_temp_patient_sub_line);
      setDearLineTxt(translation.Invite_temp_dear_line);
      setCongratsLine1Txt(translation.Invite_temp_patient_congratulation_line1);
      setPlatformtypeTxt("Patient");
      setCongratsLine2Txt(translation.Invite_temp_congratulation_line2);
      setHereLineTxt(translation.Invite_temp_here_line);
      setSigninLinetitleTxt(translation.Invite_temp_signin_title_line);
      setSigninLine1Txt(translation.Invite_temp_patient_signin_line1);
      setPlatformurl("https://me.akunah.com/");
      setPlatformurlTxt("me.akunah.com");
      setSigninLine2Txt(translation.Invite_temp_signin_line2);
      setSignupLinetitleTxt(translation.Invite_temp_signup_title);
      setSignupLine1Txt(translation.Invite_temp_signup_line1);
      setSignupLine21Txt(translation.Invite_temp_signup_line21);
      setSignupLine22Txt(translation.Invite_temp_signup_line22);
      setSignupLine3Txt(translation.Invite_temp_signup_line3);
      setSignupLine4Txt(translation.Invite_temp_patient_signup_line4);
      setExperienceLineTxt(translation.Invite_temp_experience_line);
      setThrilledLineTxt(translation.Invite_temp_thrilled_line);
      setRegardsLineTxt(translation.Invite_temp_regards_line);
      setTeamLineTxt(translation.Invite_temp_team_line);
    } else if (userRoleType === 2) {
      setSubLineTxt(translation.Invite_temp_admin_sub_line);
      setDearLineTxt(translation.Invite_temp_dear_line);
      setCongratsLine1Txt(translation.Invite_temp_org_congratulation_line1);
      setPlatformtypeTxt("Organization");
      setCongratsLine2Txt(translation.Invite_temp_congratulation_line2);
      setHereLineTxt(translation.Invite_temp_here_line);
      setSigninLinetitleTxt(translation.Invite_temp_signin_title_line);
      setSigninLine1Txt(translation.Invite_temp_org_signin_line1);
      setPlatformurl("https://org.akunah.com/");
      setPlatformurlTxt("org.akunah.com");
      setSigninLine2Txt(translation.Invite_temp_signin_line2);
      setSignupLinetitleTxt(translation.Invite_temp_signup_title);
      setSignupLine1Txt(translation.Invite_temp_signup_line1);
      setSignupLine21Txt(translation.Invite_temp_signup_line21);
      setSignupLine22Txt(translation.Invite_temp_signup_line22);
      setSignupLine3Txt(translation.Invite_temp_signup_line3);
      setSignupLine4Txt(translation.Invite_temp_org_signup_line4);
      setExperienceLineTxt(translation.Invite_temp_experience_line);
      setThrilledLineTxt(translation.Invite_temp_thrilled_line);
      setRegardsLineTxt(translation.Invite_temp_regards_line);
      setTeamLineTxt(translation.Invite_temp_team_line);
    } else if (userRoleType === 3) {
      setSubLineTxt(translation.Invite_temp_hcp_sub_line);
      setDearLineTxt(translation.Invite_temp_dear_line);
      setCongratsLine1Txt(translation.Invite_temp_hcp_congratulation_line1);
      setPlatformtypeTxt("Clinician");
      setCongratsLine2Txt(translation.Invite_temp_congratulation_line2);
      setHereLineTxt(translation.Invite_temp_here_line);
      setSigninLinetitleTxt(translation.Invite_temp_signin_title_line);
      setSigninLine1Txt(translation.Invite_temp_hcp_signin_line1);
      setPlatformurl("https://clinicians.akunah.com/");
      setPlatformurlTxt("clinicians.akunah.com");
      setSigninLine2Txt(translation.Invite_temp_signin_line2);
      setSignupLinetitleTxt(translation.Invite_temp_signup_title);
      setSignupLine1Txt(translation.Invite_temp_signup_line1);
      setSignupLine21Txt(translation.Invite_temp_signup_line21);
      setSignupLine22Txt(translation.Invite_temp_signup_line22);
      setSignupLine3Txt(translation.Invite_temp_signup_line3);
      setSignupLine4Txt(translation.Invite_temp_hcp_signup_line4);
      setExperienceLineTxt(translation.Invite_temp_experience_line);
      setThrilledLineTxt(translation.Invite_temp_thrilled_line);
      setRegardsLineTxt(translation.Invite_temp_regards_line);
      setTeamLineTxt(translation.Invite_temp_team_line);
    } else {
      setSubLineTxt(translation.Invite_temp_patient_sub_line);
      setDearLineTxt(translation.Invite_temp_dear_line);
      setCongratsLine1Txt(translation.Invite_temp_patient_congratulation_line1);
      setPlatformtypeTxt("Patient");
      setCongratsLine2Txt(translation.Invite_temp_congratulation_line2);
      setHereLineTxt(translation.Invite_temp_here_line);
      setSigninLinetitleTxt(translation.Invite_temp_signin_title_line);
      setSigninLine1Txt(translation.Invite_temp_patient_signin_line1);
      setPlatformurl("https://me.akunah.com/");
      setPlatformurlTxt("me.akunah.com");
      setSigninLine2Txt(translation.Invite_temp_signin_line2);
      setSignupLinetitleTxt(translation.Invite_temp_signup_title);
      setSignupLine1Txt(translation.Invite_temp_signup_line1);
      setSignupLine21Txt(translation.Invite_temp_signup_line21);
      setSignupLine22Txt(translation.Invite_temp_signup_line22);
      setSignupLine3Txt(translation.Invite_temp_signup_line3);
      setSignupLine4Txt(translation.Invite_temp_patient_signup_line4);
      setExperienceLineTxt(translation.Invite_temp_experience_line);
      setThrilledLineTxt(translation.Invite_temp_thrilled_line);
      setRegardsLineTxt(translation.Invite_temp_regards_line);
      setTeamLineTxt(translation.Invite_temp_team_line);
    }
  }, [userRoleType]);
  // const getMessageBody = (role) => {
  //   if (role === 1) {
  //     return translation.Invite_organization_patient_body;
  //   } else if (role === 2) {
  //     return translation.Invite_organization_admin_body;
  //   } else if (role === 3) {
  //     return translation.Invite_organization_doctor_body;
  //   } else {
  //     return "You are invited";
  //   }
  // };
  console.log(specility, "specilityspecility");
  let json;

  let clinician = {
    akou_id: organization,
    // speciality2:speciality2,
    org_name: orgNameSelected,
    email: email,
    role_type: userRoleType,
    sub_line_txt: subLineTxt,
    dear_line_txt: dearLineTxt,
    congrats_line1_txt: congratsLine1Txt,
    platform_type_txt: platformtypeTxt,
    congrats_line2_txt: congratsLine2Txt,
    plat_form_type: "",
    here_line_txt: hereLineTxt,
    signin_line_title_txt: signinLinetitleTxt,
    signin_line1_txt: signinLine1Txt,
    platform_url: platformurl,
    platform_url_txt: platformurlTxt,
    signin_line2_txt: signinLine2Txt,
    signup_line_title_txt: signupLinetitleTxt,
    signup_line1_txt: signupLine1Txt,
    signup_line21_txt: signupLine21Txt,
    signup_line22_txt: signupLine22Txt,
    signup_line3_txt: signupLine3Txt,
    signup_line4_txt: signupLine4Txt,
    experience_line_txt: experienceLineTxt,
    thrilled_line_txt: thrilledLineTxt,
    regards_line_txt: regardsLineTxt,
    team_line_txt: teamLineTxt,
  };

  let med = {
    akou_id: organization,
    // speciality2:speciality2,
    org_name: orgNameSelected,
    email: email,
    role_type: userRoleType,
    sub_line_txt: subLineTxt,
    dear_line_txt: dearLineTxt,
    congrats_line1_txt: congratsLine1Txt,
    // platform_type_txt: platformtypeTxt,
    platform_type_txt: "MED",
    congrats_line2_txt: congratsLine2Txt,
    here_line_txt: hereLineTxt,
    signin_line_title_txt: signinLinetitleTxt,
    signin_line1_txt: signinLine1Txt,
    platform_url: "https://med.akunah.com/",
    platform_url_txt: "med.akunah.com",
    plat_form_type: "",
    signin_line2_txt: signinLine2Txt,
    signup_line_title_txt: signupLinetitleTxt,
    signup_line1_txt: signupLine1Txt,
    signup_line21_txt: signupLine21Txt,
    signup_line22_txt: signupLine22Txt,
    signup_line3_txt: signupLine3Txt,
    signup_line4_txt: signupLine4Txt,
    experience_line_txt: experienceLineTxt,
    thrilled_line_txt: thrilledLineTxt,
    regards_line_txt: regardsLineTxt,
    team_line_txt: teamLineTxt,
  };

  let org_admin = {
    akou_id: organization,
    // speciality2:speciality2,
    org_name: orgNameSelected,
    email: email,
    role_type: userRoleType,
    sub_line_txt: subLineTxt,
    dear_line_txt: dearLineTxt,
    congrats_line1_txt: congratsLine1Txt,
    platform_type_txt: platformtypeTxt,
    congrats_line2_txt: congratsLine2Txt,
    here_line_txt: hereLineTxt,
    signin_line_title_txt: signinLinetitleTxt,
    signin_line1_txt: signinLine1Txt,
    platform_url: platformurl,
    platform_url_txt: platformurlTxt,
    signin_line2_txt: signinLine2Txt,
    signup_line_title_txt: signupLinetitleTxt,
    signup_line1_txt: signupLine1Txt,
    signup_line21_txt: signupLine21Txt,
    signup_line22_txt: signupLine22Txt,
    signup_line3_txt: signupLine3Txt,
    signup_line4_txt: signupLine4Txt,
    experience_line_txt: experienceLineTxt,
    thrilled_line_txt: thrilledLineTxt,
    regards_line_txt: regardsLineTxt,
    team_line_txt: teamLineTxt,
    // message_body: getMessageBody(userRoleType),
  };

  let security_admin = {
    akou_id: organization,
    // speciality2:speciality2,
    org_name: orgNameSelected,
    email: email,
    role_type: userRoleType,
    sub_line_txt: subLineTxt,
    dear_line_txt: dearLineTxt,
    congrats_line1_txt: congratsLine1Txt,
    platform_type_txt: platformtypeTxt,
    congrats_line2_txt: congratsLine2Txt,
    here_line_txt: hereLineTxt,
    signin_line_title_txt: signinLinetitleTxt,
    signin_line1_txt: signinLine1Txt,
    platform_url: platformurl,
    platform_url_txt: platformurlTxt,
    signin_line2_txt: signinLine2Txt,
    signup_line_title_txt: signupLinetitleTxt,
    signup_line1_txt: signupLine1Txt,
    signup_line21_txt: signupLine21Txt,
    signup_line22_txt: signupLine22Txt,
    signup_line3_txt: signupLine3Txt,
    signup_line4_txt: signupLine4Txt,
    experience_line_txt: experienceLineTxt,
    thrilled_line_txt: thrilledLineTxt,
    regards_line_txt: regardsLineTxt,
    team_line_txt: teamLineTxt,
    // message_body: getMessageBody(userRoleType),
  };

  let both = {
    akou_id: organization,
    // speciality2:speciality2,
    org_name: orgNameSelected,
    email: email,
    role_type: userRoleType,
    sub_line_txt: subLineTxt,
    dear_line_txt: dearLineTxt,
    congrats_line1_txt: congratsLine1Txt,
    // platform_type_txt: platformtypeTxt,
    platform_type_txt: "BOTH MED & CLINICIAN",
    congrats_line2_txt: congratsLine2Txt,
    here_line_txt: hereLineTxt,
    signin_line_title_txt: signinLinetitleTxt,
    signin_line1_txt: signinLine1Txt,
    // platform_url: "new_url",
    // platform_url_txt: platformurlTxt,
    platform_url: "clinicians.akunah.com",
    platform_url1: "med.akunah.com",
    platform_url_txt: "clinicians.akunah.com",
    platform_url_txt1: "med.akunah.com",
    plat_form_type: "5",
    signin_line2_txt: signinLine2Txt,
    signup_line_title_txt: signupLinetitleTxt,
    signup_line1_txt: signupLine1Txt,
    signup_line21_txt: signupLine21Txt,
    signup_line22_txt: signupLine22Txt,
    signup_line3_txt: signupLine3Txt,
    signup_line4_txt: signupLine4Txt,
    experience_line_txt: experienceLineTxt,
    thrilled_line_txt: thrilledLineTxt,
    regards_line_txt: regardsLineTxt,
    team_line_txt: teamLineTxt,
  };

  // console.log("User role", json)

  const addOrgAdminRequest = async () => {
    let apiUrl;
    let successMsg;
    // let faliedMsg;
    const currentUrl = window.location.hostname;

    if (userRoleType === 1) {
      let platform_ids = selectedOption.map((item) => {
        return {
          id: item.value,
          status: 1,
        };
      });

      if (
        platform_ids.some((item) => item.id === 1) &&
        platform_ids.some((item) => item.id === 2)
      ) {
        json = {
          template: both,
          platform: platform_ids,
          email: email,
          speciality2: speciality2,
          org_name: orgNameSelected,
        };
      } else if (platform_ids.some((item) => item.id === 1)) {
        json = {
          template: clinician,
          platform: platform_ids,
          email: email,
          speciality2: speciality2,
          org_name: orgNameSelected,
        };
      } else if (platform_ids.some((item) => item.id === 2)) {
        json = {
          template: med,
          platform: platform_ids,
          email: email,
          speciality2: speciality2,
          org_name: orgNameSelected,
        };
      }

      apiUrl = `extorg/admin/sendRequestHP?role=${userRoleType}&ak_id=${login.ak_id}&url=${currentUrl}&akou_id=${organization}&language=${lan}`;
      successMsg = "Patients invited successfully";
      // faliedMsg = "Patient not created";

      console.log("For request patient ", platform_ids, json);
    } else if (userRoleType === 2) {
      json = {
        template: org_admin,
        platform: [],
        email: email,
        speciality2: speciality2,
        org_name: orgNameSelected,
      };
      console.log("For request orgggg", json);
      apiUrl = `extorg/org/create_org_admin?ak_id=${login.ak_id
        }&akou_id=${organization}&language=${1}`;
      successMsg = "Organization Admins invited successfully";
      // faliedMsg = "Organization Admin not created";
    } else if (userRoleType === 3) {
      let platform_ids = selectedOption.map((item) => {
        return {
          id: item.value,
          status: 1,
        };
      });

      if (
        platform_ids.some((item) => item.id === 1) &&
        platform_ids.some((item) => item.id === 2)
      ) {
        json = {
          template: both,
          platform: platform_ids,
          email: email,
          speciality2: speciality2,
          org_name: orgNameSelected,
        };
      } else if (platform_ids.some((item) => item.id === 1)) {
        json = {
          template: clinician,
          platform: platform_ids,
          email: email,
          speciality2: speciality2,
          org_name: orgNameSelected,
        };
      } else if (platform_ids.some((item) => item.id === 2)) {
        json = {
          template: med,
          platform: platform_ids,
          email: email,
          speciality2: speciality2,
          org_name: orgNameSelected,
        };
      }

      console.log("platform_idsplatform_ids", json);
      apiUrl = `extorg/admin/sendRequestHP?role=${userRoleType}&ak_id=${login.ak_id
        }&url=${currentUrl}&akou_id=${organization}&language=${1}`;
      successMsg = "HCP's invited successfully";

      console.log("For request HCP...", platform_ids, json);
    } else if (userRoleType === 4) {
      json = {
        template: security_admin,
        platform: [],
        email: email,
        speciality2: speciality2,
        org_name: orgNameSelected,
      };
      console.log("For request orgggg", json);
      apiUrl = `extorg/admin/request_admin?ak_id=${login.ak_id}&akou_id=${organization}`;
      successMsg = "Secretary Admin invited successfully";

      //   apiUrl = `extorg/org/create_org_admin?ak_id=${login.ak_id}`;
      //   successMsg = "Organization Admin ceated";
      //   faliedMsg = "Organization Admin not created";
      console.log("role not found for user creation");
    }

    try {
      console.log("hhhhhhhhhhhjsonnn", json);
      setDisable(true);
      const response = await axiosInstance.current.post(apiUrl, json, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("responseaaa", response);
      if (userRoleType === 2 || userRoleType === 4) {
        // Validate email data
        if (!Array.isArray(email) || email.length === 0) {
          console.error("Error: Email array is empty or undefined", email);
          return;
        }

        // Validate organization ID
        if (!organization) {
          console.error("Error: Invalid organization ID", organization);
          return;
        }
        // Resetting UI and states
        setMessage(successMsg);
        setMessageType("success");
        setDisable(false);
        setLan("");
        setSameEmailError(false);
        dispatch(noteRefs(!dataRefe));
        hideModal(false);
        setAdd("");
        setEmail([]);
        setTimeout(() => {
          setMessage(false);
          },3000);
        // Construct data object
        let newDataList = email
          .map((emailItem) => ({
            firstname: emailItem?.fname || "",
            lastname: emailItem?.lname || "",
            email: emailItem?.email || "",
            ak_id: organization || "",
            account_status: 1,
            platform:
              Array.isArray(selectedOption) && selectedOption.length > 0
                ? selectedOption.map((item) => (item.value))
                : [],
            practitioner: 0,
            quick_code: "",
            insightStatus: true,
            interOrgStatus: false,
            reportStatus: true,
          }))
          .filter(
            (newItem) =>
              !orgUserAdminList.some(
                (existingItem) => existingItem.ak_id === newItem.ak_id
              ) // Exclude duplicates
          );

        console.log("Filtered new data list:", newDataList);

        try {
          // Ensure previous state is an array
          setOrgUserAdminList((prevList) =>
            Array.isArray(prevList) ? [...newDataList, ...prevList] : [...newDataList]
          );

          console.log("State updated successfully.");
        } catch (error) {
          console.error("Error updating state:", error);
        }

      }
      else {
        const result = response.data?.data; // Ensure response.data.data exists
        console.log("result", result);

        // Validate result before accessing properties
        if (!Array.isArray(result) || result.length === 0) {
          console.error("Invalid or empty result array:", result);
          return; // Exit function if result is invalid
        }

        if (!Array.isArray(email) || email.length === 0) {
          console.error("Invalid or empty email array:", email);
          return; // Exit function if email is invalid
        }


        let responseData = [];
        let responseData2 = [];

        // Processing result data
        result.forEach((ele) => {
          if (ele?.status === false) {
            console.log("elelele", ele);
            responseData.push(ele);
          } else {
            responseData2.push(ele);
          }
        });

        // Updating states after processing
        setLan("");
        setErrorEmailData(responseData);
        setEmailSuccessData(responseData2);

        console.log("responnnsnsns", responseData);

        setTimeout(() => {
          setMessage(false);
          if (responseData.length === 0) {
            setMessage(successMsg);
            setMessageType("success");
          }
        }, 3000);

        if (responseData.length === 0) {
          hideModal(false);
        }

        // Resetting form fields and states
        setDisable(false);
        setAdd("");
        setEmail([]);
        setSameEmailError(false);
        dispatch(noteRefs(!dataRefe));

        // Generate new data list
        let newDataList = email
          .map((emailItem, index) => {
            let matchingResult = result[index] || result[0] || {};
            console.log(`🔍 Matching result for email index ${index}:`, matchingResult);

            return {
              firstname: emailItem?.fname || "",
              lastname: emailItem?.lname || "",
              email: emailItem?.email || "",
              ak_id: matchingResult?.id || "", // Assign corresponding id, fallback to first result
              platform:
                Array.isArray(selectedOption) && selectedOption.length > 0
                  ? selectedOption.map((item) => (item.value))
                  : [],
              insightStatus: true,
              interOrgStatus: false,
              reportStatus: true,
              status: matchingResult?.status !== undefined ? matchingResult.status : true, // Ensure status is always present
            };
          })
          .filter((newItem) => {
            const isValid = newItem.status !== false &&
              !orgUserHcpList.some((existingItem) => existingItem.ak_id === newItem.ak_id);

            if (!isValid) {
              console.warn("⚠ Filtering out item:", newItem);
            }

            return isValid;
          });

        console.log("✅ Filtered new data list:", newDataList);

        // Update state lists with the new filtered data
        setOrgUserHcpList((prevList) => 
          Array.isArray(prevList) ? [...newDataList, ...prevList] : [...newDataList]
        );
        
        setOrgUserPatientList((prevList) => 
          Array.isArray(prevList) ? [...newDataList, ...prevList] : [...newDataList]
        );

        console.log("State updated successfully.")
      }



    } catch (error) {
      setDisable(false);
      setSameEmailError(false);
      setMessage(error.response?.data?.data);
      setMessageType("error");
      setTimeout(() => {
        setMessage(false);
      }, 2000);
      // dispatch(apiResponseMsgData(error.response.data.data));
      // dispatch(apiResponseMsgTypeData("error"));
    }
  };

  console.log("responseDataresponseData", errorEmailData);

  const handleEditRequest = () => {
    if (
      email?.length === 0 ||
      ((userRoleType === 3 || userRoleType === 1) &&
        selectedOption.length === 0)
    ) {
      setEmailListError(true);
      setErrorEmailData([]);
      setEmailSuccessData([]);
      setPlatError(true);
      setSameEmailError(false);
    } else if (organization?.length === 0) {
      orgNameRef.current.errUpdate();
    } else {
      addOrgAdminRequest();
    }
  };

  useEffect(() => {
    if (email?.length !== 0) {
      setEmailListError(false);
    }
  }, [email?.length])
  const handleOrgChange = (e) => {
    setOrganization(e);
  };
  // const handleSpeciality = (e) => {
  //   setSpeciality2(e);
  // };

  const handleSpeciality = (event, selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      const selectedValues = selectedOptions.map((option) => option.value); // Extract the values of selected options
      setSpeciality2(selectedValues); // Update state with an array of selected values
    } else {
      setSpeciality2([]); // Clear the selection if nothing is selected
    }
  };

  console.log(speciality2, "speciality2speciality2");

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  let validators = [
    {
      check: Validators.email,
      message: "Not a valid email",
    },
  ];
  useEffect(() => {
    if (add) {
      setEmailcheck(false);
      setEmailerror(validateInput(validators, add));
    }
  }, [add]);
  const addEmails = (e) => {
    setEmailcheck(true);
    setErrorEmailData([]);
    setEmailSuccessData([]);
    if (!isValidEmail(add) || !add || !fname || !lname) {
      setEmailcheck(true);
      emailRef.current.errUpdate(); // Show error for invalid email
    } else if (email.map((item) => item.email).includes(add)) {
      setSameEmailError(true);
      console.error("Error: Duplicate email found.");
    } else {
      setEmail((oldEmail) => {
        let data = {
          email: add,
          fname: fname,
          lname: lname,
        };
        return [...oldEmail, data]; // Add valid email to the list
      });
      setAdd("");
      setFname("");
      setLname("");
      setEmailcheck(false);
      setSameEmailError(false);
    }
  };

  const handleRemoveItem = (item) => {
    setEmail((prevState) => prevState.filter((prevItem) => prevItem !== item));
    setMessage("");
  };
  useEffect(() => {
    if (defaultData?.length > 0) {
      setOrganization(defaultData[0].value);
    }
  }, [defaultData]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });

  useEffect(() => {
    if (showModal === false) {
      setAdd("");
      setEmailcheck(false);
      setEmailerror(false);
      setEmail([]);
      setSelectedOption([]);
      setEmailListError(false);
      setPlatError(false);
      setErrorEmailData([]);
      setEmailSuccessData([]);
      setSameEmailError(false);
      setSpeciality2([]);
      setFname("");
      setLname("");
    }
  }, [showModal]);

  console.log(
    "editPlatformList , newPlatFormData",
    editPlatformList,
    newPlatFormData
  );

  useEffect(() => {
    if (userRoleType === 3 || userRoleType === 1) {
      if (platForm !== undefined) {
        console.log("comming 222222");
        setFilterData(
          platFormData.filter((item) => platForm.includes(item.value))
        );
      } else {
        console.log("comming 111111");
        setFilterData(
          newPlatFormData &&
          newPlatFormData.filter((item) =>
            editPlatformList?.includes(item.value)
          )
        );
      }
    }
  }, [platForm, userRoleType]);

  console.log("filterDatafilterDatafilterDatafilterData", filterData);

  const handleChageErrorData = (id) => {
    const updatedErrorEmailData = errorEmailData.filter((ele) => ele.id !== id);
    setErrorEmailData(updatedErrorEmailData);
  };

  const handleChageRightErrorData = (id) => {
    const updatedErrorEmailSuccessData = emailSuccesData.filter(
      (ele) => ele.id !== id
    );
    setEmailSuccessData(updatedErrorEmailSuccessData);
  };

  console.log(speciality2, specility, "speciality222");
  console.log("platFormData", platFormData, platForm);
  useEffect(() => {
    if (specility === undefined || specility === "" || specility === null) {
      specility = receivedData;
    }
  }, [specility]);

  return (
    <>
      {message && <Message message={message} type={messageType} />}

      <Modal
        show={showModal}
        onHide={hideModal}
        className={``}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-md patient_notes_popup org__type_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {userRoleType === 1 && <>Invite a Patient</>}
            {userRoleType === 2 && <>Invite an Organization Admin</>}
            {userRoleType === 3 && <>Invite an HCP</>}
            {userRoleType === 4 && <>Invite an Secretary</>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          {userRoleType === 3 || userRoleType === 1 ? (
            <>
              <div className="mb_20">
                <label>Platforms</label>
                <Select
                  value={selectedOption}
                  isMulti
                  onChange={setSelectedOption}
                  options={filterData}
                  className="multi_select_drp_select_plat"
                  placeholder="Select Platforms"
                />
                {selectedOption?.length === 0 && (
                  <>
                    {platError && (
                      <span className="text-danger" style={{ marginLeft: "6px" }}>
                        This field is required
                      </span>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            ""
          )}
          {/* <table style={{ width: "100%" }}>
            <tr>
              <th style={{ paddingRight:"5px" }}> */}
          {organization_id ? (
            <InputField
              label="Organization Id *"
              value={organization_id}
              type="text"
              ref={orgNameRef}
              placeholder="Enter org here"
              styleClass="br_10 mxw_100 mb_20"
              onChange={keyNameChange}
              disabled={true}
              validators={[
                {
                  check: Validators.required,
                  message: "This field is required",
                },
                {
                  check: Validators.email,
                  message: "Only contain Email",
                },
              ]}
            />
          ) : (
            <Dropdown
              data={defaultData}
              label="Organization Name*"
              value={organization}
              onChange={handleOrgChange}
              placeholder="Select"
              styleClass="mxw_100 br_10 mb_20"
              ref={orgNameRef}
              validators={[
                {
                  check: Validators.required,
                  message: "This field is required",
                },
              ]}
              disabled={readOnly}
            />
          )}
          {/* </th>
              <th style={{ width:"257px" }}> */}
          {userRoleType === 1 ? (
            <Dropdown
              data={language.map((item) => {
                return {
                  ...item,
                  value: item.id,
                };
              })}
              label="Language"
              value={lan}
              onChange={handleLanchange}
              placeholder="Select"
              styleClass="mxw_100 br_10 mb_20"
            // ref={orgNameRef}
            // validators={[
            //   {
            //     check: Validators.required,
            //     message: "This field is required",
            //   },
            // ]}
            />
          ) : (
            ""
          )}
          {/* </th>
              </tr>
              </table> */}




          {/* <Autocomplete
            // data={specility}
            data={specility.map((item)=>{
              return{
              label:item.name,
              value:item.id
              }
            })}
            label="specility*"
            value={speciality2}
            onChange={handleSpeciality}
            placeholder="Select"
            styleClass="mxw_100 br_10 mb_20"
            ref={orgNameRef}
            validators={[
              {
                check: Validators.required,
                message: "This field is required",
              },
            ]}
          /> */}
          <div className="mb_20">
            {userRoleType === 3 && (
              <>
                <label>Select Speciality</label>
                <Autocomplete
                  id="free-solo-demo"
                  multiple
                  className="w-100"
                  style={{ borderRadius: "0px" }}
                  options={orgSpecc
                    ?.filter((item) => !speciality2.includes(item.id))
                    ?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  getOptionLabel={(option) => option?.label}
                  onChange={handleSpeciality}
                  renderInput={(params) => (
                    <TextField
                      style={{ border: "none" }}
                      placeholder={
                        speciality2.length === 0 ? "Select Speciality" : ""
                      }
                      InputLabelProps={{
                        shrink: false,
                      }}
                      sx={{
                        "& ::placeholder": {
                          color: "#3f2783",
                        },
                      }}
                      {...params}
                      label=""
                    />
                  )}
                />
              </>
            )}
          </div>
          {email?.length !== 0 && (
            <div
              className="border"
              style={{
                padding: "15px",
                borderRadius: "8px",
                // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                marginBottom: "16px"
              }}
            >
              <table
                className="table m-0"
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  textAlign: "left",
                }}
              >
                <thead style={{ backgroundColor: "#3f2783", color: "white" }}>
                  <tr>
                    <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>
                      First name
                    </th>
                    <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>
                      Last name
                    </th>
                    <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>
                      Email
                    </th>
                    <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {email.map((itemval, index) => (
                    <tr key={index}>
                      <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
                        {itemval.fname}
                      </td>
                      <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
                        {itemval.lname}
                      </td>
                      <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
                        {itemval.email}
                      </td>
                      <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
                        <span onClick={() => handleRemoveItem(itemval)} data-bs-toggle="tooltip" title={"Delete"}>
                          <img className={`action_icons`} src={deleteIcon} style={{ cursor: "pointer" }} alt="Icon" />
                        </span>
                        {/* <button
                style={{
                  backgroundColor: "#dc3545",
                  color: "white",
                  border: "none",
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveItem(itemval)}
              >
                x
              </button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <table style={{ width: "100%" }}>
            <tr>
              <th >
                <InputField
                  label="First name*"
                  value={fname}
                  type="text"
                  // ref={emailRef}
                  placeholder="Enter First name here"
                  styleClass="br_10 mxw_100 mb_20 "
                  onChange={keyFNameChange}
                />
              </th>
              <th style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                <InputField
                  label="Last name*"
                  value={lname}
                  type="text"
                  // ref={emailRef}
                  placeholder="Enter Last name here"
                  styleClass="br_10 mxw_100 mb_20"
                  onChange={keyLNameChange}
                />
              </th>
              <th >
                <InputField
                  label={userRoleType === 1 ? "* Email" : userRoleType === 2 ? "* Admin User Email*" : userRoleType === 3 ? "* Email" : "* Admin User Email*"}
                  value={add}
                  type="text"
                  ref={emailRef}
                  placeholder="Enter email here"
                  styleClass="br_10 mxw_100 mb_20"
                  onChange={keyNameChange}
                />
              </th>
              <th style={{ paddingLeft: "5px", width: "33px", height: "33px" }}>

                <span class="add_btn1" onClick={addEmails}><img src={PlusIcon} class="icon_style" data-bs-toggle="tooltip" data-bs-placement="top" title="Add more" alt="Add-Email" /></span>

              </th>
            </tr>
            <tr>
              <td>
                {emailcheck && (
                  <>
                    {fname === "" || lname === '' || add === '' ? (
                      <span className="text-danger">
                        This field is required
                      </span>
                    ) : (
                      ""
                    )}


                  </>
                )}
                {emailError && add !== "" ? (
                  <>
                    <span className="text-danger">Not a valid email</span>
                  </>
                ) : (
                  ""
                )}
              </td>
              {/* <td>
                {emailcheck && (
                  <>
                    {lname === "" ? (
                      <span className="text-danger">
                        This field is required
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </td>
              <td>
                {emailcheck && (
                  <>
                    {add === "" ? (
                      <span className="text-danger">
                        This field is required
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                )}

                {emailError && add !== "" ? (
                  <>
                    <span className="text-danger">Not a valid email</span>
                  </>
                ) : (
                  ""
                )}

                {sameEmailError ? (
                  <span className="text-danger">This email already added</span>
                ) : (
                  ""
                )}
              </td> */}

            </tr>
          </table>

          {/* </div> */}
          {/* <div className="d-flex"> */}

          {/* email check validation */}

          {/* {errorEmailData.length === 0 ? "" : <p>This Email Id's Already Present in this Organization</p>} */}

          {/* <div className="row">
            <div className="col-md-12" >
              {

                errorEmailData && errorEmailData.map((ele) => {
                  return (
                    <>
                      {
                        ele.status === false ? (

                          <div class="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>Email : {ele.email} , Ak_id: {ele.id} ---- {ele.data}</strong>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>

                        ) : ("")
                      }
                    </>
                  )
                })
              }

            </div>
          </div> */}
          <div className="row">
            {errorEmailData?.length === 0 ? (
              ""
            ) : (
              <p className="emailError">
                Add/Rejected Email id's for{" "}
                {userRoleType === 1
                  ? "Patients"
                  : userRoleType === 3
                    ? "HCP's"
                    : ""}{" "}
                in this organization *
              </p>
            )}
            <div
              className="col-md-12 scrollbar_vert"
              style={{ maxHeight: "27vh" }}
            >
              {emailSuccesData &&
                emailSuccesData.map((ele) => {
                  return (
                    <div
                      key={ele.id}
                      class="alert alert-success alert-dismissible fade show"
                      role="alert"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-check2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                      </svg>
                      <strong className="ms-2">Email : {ele.email} </strong>
                      <br />
                      <strong className="ms-4">Ak_id: {ele.id}</strong>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => handleChageRightErrorData(ele.id)}
                      ></button>
                    </div>
                  );
                })}
              {errorEmailData &&
                errorEmailData.map((ele) => (
                  <div
                    key={ele.id}
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-exclamation-triangle"
                      viewBox="0 0 16 16"
                    >
                      {/* SVG paths */}
                    </svg>
                    <strong className="ms-2">Email : {ele.email} </strong>
                    <br />
                    <strong className="ms-4">Ak_id: {ele.id}</strong>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => handleChageErrorData(ele.id)}
                    ></button>
                  </div>
                ))}
            </div>
          </div>

          {/* <div className="text-right">
            <Button
              onClick={addEmails}
              value="+ Add Email"
              buttonStyle="btn_fill mr_20"
            />
          </div> */}

          {emailListError && (
            <span className="text-danger">Add at least one email</span>
          )}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "flex-start" }}>
          <Button
            onClick={handleCloseEdit}
            value="Cancel"
            buttonStyle="btn_nill mr_20"
          />
          <Button
            onClick={handleEditRequest}
            value="Save"
            width="120px"
            enable={disble}
            buttonStyle="btn_fill ms-4 clk_btn_disbled"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddOrgAdmin;